/* tslint:disable */
/* eslint-disable */

import {ConcreteRequest} from 'relay-runtime';
import {FragmentRefs} from 'relay-runtime';
export type LeaveSortField =
  | 'APPROVED_DATE'
  | 'CREATED_DATE'
  | 'DAYS'
  | 'END_DATE'
  | 'START_DATE'
  | 'STATUS'
  | 'TYPE'
  | '%future added value';
export type SortDirection = 'ASCENDING' | 'DESCENDING' | '%future added value';
export type WorkflowAction =
  | 'APPROVE'
  | 'DECLINE'
  | 'UNDO'
  | '%future added value';
export type ActionLeaveInput = {
  action: WorkflowAction;
  clientMutationId?: string | null;
  taskId: number;
};
export type LeaveSort = {
  field: LeaveSortField;
  direction?: SortDirection | null;
};
export type ActionLeaveMutationVariables = {
  input: ActionLeaveInput;
  search?: string | null;
  first?: number | null;
  after?: string | null;
  sort?: LeaveSort | null;
};
export type ActionLeaveMutationResponse = {
  readonly actionLeave: {
    readonly clientMutationId: string | null;
    readonly processInstanceId: string | null;
    readonly user: {
      readonly ' $fragmentRefs': FragmentRefs<
        'LeavesPage_leaves' | 'TaskPage_tasks' | 'Header_userInformation'
      >;
    };
  };
};
export type ActionLeaveMutation = {
  readonly response: ActionLeaveMutationResponse;
  readonly variables: ActionLeaveMutationVariables;
};

/*
mutation ActionLeaveMutation(
  $input: ActionLeaveInput!
  $search: String
  $first: Int
  $after: String
  $sort: LeaveSort
) {
  actionLeave(input: $input) {
    clientMutationId
    processInstanceId
    user {
      ...LeavesPage_leaves
      ...TaskPage_tasks
      ...Header_userInformation
      id
    }
  }
}

fragment Header_userInformation on User {
  firstName
  profileImageUri
  tasks(q: $search, first: $first, after: $after) {
    total
  }
}

fragment LeavesPage_leaves on User {
  id
  firstName
  lastName
  email
  mobileNumber
  leaves(q: $search, first: $first, after: $after, sort: $sort) {
    total
    edges {
      node {
        id
        startDate
        endDate
        days
        dateCreated
        dateApproved
        status
        typeId
        typeDescription
      }
    }
  }
}

fragment TaskPage_tasks on User {
  id
  firstName
  lastName
  email
  mobileNumber
  tasks(q: $search, first: $first, after: $after) {
    total
    edges {
      node {
        id
        description
        dateCreated
        taskId
        taskType
        time
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'input',
        type: 'ActionLeaveInput!',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'search',
        type: 'String',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'first',
        type: 'Int',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'after',
        type: 'String',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'sort',
        type: 'LeaveSort',
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'input',
        variableName: 'input',
      },
    ],
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'clientMutationId',
      storageKey: null,
    },
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'processInstanceId',
      storageKey: null,
    },
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v5 = {
      kind: 'Variable',
      name: 'after',
      variableName: 'after',
    },
    v6 = {
      kind: 'Variable',
      name: 'first',
      variableName: 'first',
    },
    v7 = {
      kind: 'Variable',
      name: 'q',
      variableName: 'search',
    },
    v8 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'total',
      storageKey: null,
    },
    v9 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'dateCreated',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'ActionLeaveMutation',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'ActionLeavePayload',
          kind: 'LinkedField',
          name: 'actionLeave',
          plural: false,
          selections: [
            v2 /*: any*/,
            v3 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: 'User',
              kind: 'LinkedField',
              name: 'user',
              plural: false,
              selections: [
                {
                  args: null,
                  kind: 'FragmentSpread',
                  name: 'LeavesPage_leaves',
                },
                {
                  args: null,
                  kind: 'FragmentSpread',
                  name: 'TaskPage_tasks',
                },
                {
                  args: null,
                  kind: 'FragmentSpread',
                  name: 'Header_userInformation',
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Mutation',
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'ActionLeaveMutation',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'ActionLeavePayload',
          kind: 'LinkedField',
          name: 'actionLeave',
          plural: false,
          selections: [
            v2 /*: any*/,
            v3 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: 'User',
              kind: 'LinkedField',
              name: 'user',
              plural: false,
              selections: [
                v4 /*: any*/,
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'firstName',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'lastName',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'email',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'mobileNumber',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: [
                    v5 /*: any*/,
                    v6 /*: any*/,
                    v7 /*: any*/,
                    {
                      kind: 'Variable',
                      name: 'sort',
                      variableName: 'sort',
                    },
                  ],
                  concreteType: 'LeaveConnection',
                  kind: 'LinkedField',
                  name: 'leaves',
                  plural: false,
                  selections: [
                    v8 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      concreteType: 'LeaveConnectionEdge',
                      kind: 'LinkedField',
                      name: 'edges',
                      plural: true,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          concreteType: 'Leave',
                          kind: 'LinkedField',
                          name: 'node',
                          plural: false,
                          selections: [
                            v4 /*: any*/,
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'startDate',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'endDate',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'days',
                              storageKey: null,
                            },
                            v9 /*: any*/,
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'dateApproved',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'status',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'typeId',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'typeDescription',
                              storageKey: null,
                            },
                          ],
                          storageKey: null,
                        },
                      ],
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
                {
                  alias: null,
                  args: [v5 /*: any*/, v6 /*: any*/, v7 /*: any*/],
                  concreteType: 'TaskConnection',
                  kind: 'LinkedField',
                  name: 'tasks',
                  plural: false,
                  selections: [
                    v8 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      concreteType: 'TaskConnectionEdge',
                      kind: 'LinkedField',
                      name: 'edges',
                      plural: true,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          concreteType: 'Task',
                          kind: 'LinkedField',
                          name: 'node',
                          plural: false,
                          selections: [
                            v4 /*: any*/,
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'description',
                              storageKey: null,
                            },
                            v9 /*: any*/,
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'taskId',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'taskType',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'time',
                              storageKey: null,
                            },
                          ],
                          storageKey: null,
                        },
                      ],
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'profileImageUri',
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      id: null,
      metadata: {},
      name: 'ActionLeaveMutation',
      operationKind: 'mutation',
      text:
        'mutation ActionLeaveMutation(\n  $input: ActionLeaveInput!\n  $search: String\n  $first: Int\n  $after: String\n  $sort: LeaveSort\n) {\n  actionLeave(input: $input) {\n    clientMutationId\n    processInstanceId\n    user {\n      ...LeavesPage_leaves\n      ...TaskPage_tasks\n      ...Header_userInformation\n      id\n    }\n  }\n}\n\nfragment Header_userInformation on User {\n  firstName\n  profileImageUri\n  tasks(q: $search, first: $first, after: $after) {\n    total\n  }\n}\n\nfragment LeavesPage_leaves on User {\n  id\n  firstName\n  lastName\n  email\n  mobileNumber\n  leaves(q: $search, first: $first, after: $after, sort: $sort) {\n    total\n    edges {\n      node {\n        id\n        startDate\n        endDate\n        days\n        dateCreated\n        dateApproved\n        status\n        typeId\n        typeDescription\n      }\n    }\n  }\n}\n\nfragment TaskPage_tasks on User {\n  id\n  firstName\n  lastName\n  email\n  mobileNumber\n  tasks(q: $search, first: $first, after: $after) {\n    total\n    edges {\n      node {\n        id\n        description\n        dateCreated\n        taskId\n        taskType\n        time\n      }\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = '81f8ff5c0a757087bb8d8b4e8d7af669';
export default node;
