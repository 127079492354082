import React, {useRef, useState} from 'react';
import AvatarEditor from 'react-avatar-editor';
import Slider from '@material-ui/core/Slider';
import Box from '@material-ui/core/Box';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

interface Props {
  image: string | File;
  handleClose: () => void;
  setImage(image?: Blob): void;
}

export function ImageEditor({image, handleClose, setImage}: Props) {
  const [zoom, setZoom] = useState(1.2);
  const [rotate, setRotate] = useState(0);
  const canvasScaledRef = useRef<AvatarEditor>(null);
  const [innerImageFile] = useState<Blob>();

  return (
    <Box
      pt={2}
      display="flex"
      flexDirection="column"
      maxWidth="400px"
      margin="0 auto"
      alignItems="center"
      style={{boxSizing: 'border-box'}}
    >
      <Box flexBasis="250px" flexGrow={1}>
        <AvatarEditor
          image={image}
          ref={canvasScaledRef}
          //width={250}
          //height={250}
          style={{width: '100%', maxWidth: '250'}}
          border={50}
          borderRadius={150}
          color={[255, 255, 255, 0.6]} // RGBA
          rotate={rotate}
          scale={zoom}
        />
      </Box>
      <Box display="flex" justifyContent="space-between" width="100%">
        <IconButton
          onClick={() => {
            setRotate(rotate - 2);
          }}
        >
          <RotateLeftIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            setRotate(rotate + 90);
          }}
        >
          <RotateRightIcon />
        </IconButton>
      </Box>
      <Typography variant="body1">Zoom</Typography>
      <Slider
        max={10}
        step={0.2}
        min={1}
        value={zoom}
        onChange={(_event, newValue: number | number[]) => {
          setZoom(newValue as number);
        }}
      />

      <Button
        fullWidth
        variant="contained"
        size="large"
        color="primary"
        onClick={function () {
          if (canvasScaledRef.current) {
            canvasScaledRef.current.getImageScaledToCanvas().toBlob((blob) => {
              blob && setImage(blob);
            });
          } else {
            setImage(innerImageFile);
          }

          handleClose();
        }}
      >
        Select Picture
      </Button>
      <Box py={3} width={1}>
        <Button
          fullWidth
          variant="text"
          size="large"
          color="primary"
          onClick={function () {
            handleClose();
          }}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
}
