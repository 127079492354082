import React from 'react';
import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import {Field} from 'formik';

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      borderRadius: '10px',
      marginBottom: '12px',
      minHeight: '115px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      '&:hover': {
        background: '#faab6b',
        cursor: 'pointer',
      },
    },
    innerPaper: {
      '&:hover': {
        background: 'inherit',
      },
    },
  })
);

interface AddressProps {
  id: string;
  building: string | null;
  street: string;
  city: string;
  postcode: string;
  province: string;
  country: string;
  preferred: boolean;
}

interface Props {
  data: readonly AddressProps[] | undefined;
  editAddress: Function;
}

export const UserAddress = ({data, editAddress}: Props) => {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column">
      <Field component={RadioGroup} name="address">
        {data?.map((value) => {
          return (
            <Paper
              className={classes.paper}
              defaultChecked={value.preferred}
              onClick={() => editAddress(value)}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm container>
                  <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs>
                      <Typography gutterBottom variant="subtitle1">
                        {`${value.building} ${value.street}`}
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom>
                        {value.city}
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom>
                        {`${value.province} ${value.postcode}`}
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom>
                        {value.country}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          );
        })}
      </Field>
    </Box>
  );
};
