/* tslint:disable */
/* eslint-disable */

import {ConcreteRequest} from 'relay-runtime';
export type RequestPasswordResetInput = {
  clientMutationId?: string | null;
  clientId: string;
};
export type ForgotPasswordPageMutationVariables = {
  input: RequestPasswordResetInput;
};
export type ForgotPasswordPageMutationResponse = {
  readonly requestPasswordReset: {
    readonly clientMutationId: string | null;
    readonly emailAddress: string;
  };
};
export type ForgotPasswordPageMutation = {
  readonly response: ForgotPasswordPageMutationResponse;
  readonly variables: ForgotPasswordPageMutationVariables;
};

/*
mutation ForgotPasswordPageMutation(
  $input: RequestPasswordResetInput!
) {
  requestPasswordReset(input: $input) {
    clientMutationId
    emailAddress
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'input',
        type: 'RequestPasswordResetInput!',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'input',
            variableName: 'input',
          },
        ],
        concreteType: 'RequestPasswordResetPayload',
        kind: 'LinkedField',
        name: 'requestPasswordReset',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'clientMutationId',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'emailAddress',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'ForgotPasswordPageMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'ForgotPasswordPageMutation',
      selections: v1 /*: any*/,
    },
    params: {
      id: null,
      metadata: {},
      name: 'ForgotPasswordPageMutation',
      operationKind: 'mutation',
      text:
        'mutation ForgotPasswordPageMutation(\n  $input: RequestPasswordResetInput!\n) {\n  requestPasswordReset(input: $input) {\n    clientMutationId\n    emailAddress\n  }\n}\n',
    },
  };
})();
(node as any).hash = '3f7647ff0e3f247ca698736f98997ac0';
export default node;
