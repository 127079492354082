/* tslint:disable */
/* eslint-disable */

import {ConcreteRequest} from 'relay-runtime';
export type SaveUserTimesheetInput = {
  clientMutationId?: string | null;
  userTimesheet?: UserTimesheetInput | null;
  timesheetId: string;
};
export type UserTimesheetInput = {
  id?: string | null;
  timesheetId: string;
  userId?: string | null;
  totalActualHours?: number | null;
  lines: Array<UserTimesheetLineInput>;
};
export type UserTimesheetLineInput = {
  id: string;
  userTimesheetId?: string | null;
  position: number;
  description?: string | null;
  actualHours?: number | null;
};
export type CaptureTimesheetPageMutationVariables = {
  input: SaveUserTimesheetInput;
};
export type CaptureTimesheetPageMutationResponse = {
  readonly saveUserTimesheet: {
    readonly userTimesheet: {
      readonly id: string | null;
      readonly totalActualHours: number | null;
      readonly userId: string | null;
      readonly lines: ReadonlyArray<{
        readonly id: string | null;
        readonly userTimesheetId: string | null;
        readonly position: number;
        readonly description: string | null;
        readonly actualHours: number | null;
      }>;
      readonly timesheet: {
        readonly id: string;
        readonly billableHours: number;
        readonly description: string;
        readonly name: string;
        readonly startDate: string;
        readonly endDate: string;
      };
    };
  };
};
export type CaptureTimesheetPageMutation = {
  readonly response: CaptureTimesheetPageMutationResponse;
  readonly variables: CaptureTimesheetPageMutationVariables;
};

/*
mutation CaptureTimesheetPageMutation(
  $input: SaveUserTimesheetInput!
) {
  saveUserTimesheet(input: $input) {
    userTimesheet {
      id
      totalActualHours
      userId
      lines {
        id
        userTimesheetId
        position
        description
        actualHours
      }
      timesheet {
        id
        billableHours
        description
        name
        startDate
        endDate
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'input',
        type: 'SaveUserTimesheetInput!',
      },
    ],
    v1 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'description',
      storageKey: null,
    },
    v3 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'input',
            variableName: 'input',
          },
        ],
        concreteType: 'SaveUserTimesheetPayload',
        kind: 'LinkedField',
        name: 'saveUserTimesheet',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: 'UserTimesheet',
            kind: 'LinkedField',
            name: 'userTimesheet',
            plural: false,
            selections: [
              v1 /*: any*/,
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'totalActualHours',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'userId',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                concreteType: 'UserTimesheetLine',
                kind: 'LinkedField',
                name: 'lines',
                plural: true,
                selections: [
                  v1 /*: any*/,
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'userTimesheetId',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'position',
                    storageKey: null,
                  },
                  v2 /*: any*/,
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'actualHours',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                concreteType: 'Timesheet',
                kind: 'LinkedField',
                name: 'timesheet',
                plural: false,
                selections: [
                  v1 /*: any*/,
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'billableHours',
                    storageKey: null,
                  },
                  v2 /*: any*/,
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'name',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'startDate',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'endDate',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'CaptureTimesheetPageMutation',
      selections: v3 /*: any*/,
      type: 'Mutation',
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'CaptureTimesheetPageMutation',
      selections: v3 /*: any*/,
    },
    params: {
      id: null,
      metadata: {},
      name: 'CaptureTimesheetPageMutation',
      operationKind: 'mutation',
      text:
        'mutation CaptureTimesheetPageMutation(\n  $input: SaveUserTimesheetInput!\n) {\n  saveUserTimesheet(input: $input) {\n    userTimesheet {\n      id\n      totalActualHours\n      userId\n      lines {\n        id\n        userTimesheetId\n        position\n        description\n        actualHours\n      }\n      timesheet {\n        id\n        billableHours\n        description\n        name\n        startDate\n        endDate\n      }\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = '84bb0edf2d39eaf2409b2f3392996a7c';
export default node;
