import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import {
  CircularProgress,
  Box,
  Typography,
  Avatar,
  IconButton,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import {theme} from '@ssquared/sep/web/theme/Theme';
import Modal from '@material-ui/core/Modal';
import {ImageEditor} from './ImageEditor';
import {useMutation} from 'relay-hooks';
import {UserProfilePictureMutation} from '@ssquared/sep/web/__generated__/UserProfilePictureMutation.graphql';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';

interface Props {
  userID: string;
  image?: string;
  displayName: string;
  setUrl(url: string): void;
}

function toBase64(file: File): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      height: '100%',
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2, 4, 3),
    },

    addPhoto: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  })
);

export function UserProfilePicture({
  userID,
  image,
  displayName,
  setUrl,
}: Props) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  let url = '';

  // alert(userID);

  const [loadingImage, setLoadingImage] = React.useState(false);
  const [innerImage, setInnerImage] = React.useState('');

  const [mutate] = useMutation<UserProfilePictureMutation>(graphql`
    mutation UserProfilePictureMutation(
      $input: GenerateProfilePictureUrlInput!
    ) {
      generateProfilePictureUrl(input: $input) {
        putUrl
        getUrl
      }
    }
  `);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box display="flex" justifyContent="center" px={2} pb={2}>
      {!loadingImage ? (
        <Avatar
          src={image}
          style={{
            backgroundColor: 'darkGrey',
            height: theme.spacing(8),
            width: theme.spacing(8),
          }}
        />
      ) : (
        <Avatar
          src={''}
          style={{
            backgroundColor: 'darkGrey',
            height: theme.spacing(8),
            width: theme.spacing(8),
          }}
        >
          <CircularProgress style={{color: 'white'}} />
        </Avatar>
      )}
      <Box
        display="flex"
        flexDirection="column-reverse"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        style={{
          transform: 'translate(80%, 130%)',
          height: '28px',
          width: '28px',
          borderRadius: '50%',
          backgroundColor: 'lightGrey',
        }}
      >
        <IconButton>
          <input
            accept="image/*"
            style={{display: 'none'}}
            id="contained-button-file"
            type="file"
            key={Date.now()}
            onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
              handleOpen();
              if (
                event &&
                event.target &&
                event.target.files &&
                event.target.files.length > 0
              ) {
                const file = event.target.files[0];
                const img = await toBase64(file);

                if (typeof img === 'string') {
                  setInnerImage(img);
                }
              }
            }}
          />
          <Modal
            open={open}
            onClose={handleClose}
            style={{overflow: 'scroll', position: 'absolute'}}
          >
            <>
              <Box
                display="flex"
                pt={'12px'}
                pl={'16px'}
                style={{backgroundColor: 'white'}}
              >
                <IconButton
                  style={{
                    backgroundColor: '#F2F8FA',
                    width: '48px',
                    height: '28px',
                    borderRadius: '100px',
                  }}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <ArrowBackRoundedIcon />
                </IconButton>
                <Box pl={'11px'}>
                  <Typography variant="h5">Edit Profile Picture</Typography>
                </Box>
              </Box>
              <Box className={classes.paper}>
                <ImageEditor
                  handleClose={handleClose}
                  image={innerImage}
                  setImage={async (image) => {
                    setLoadingImage(true);
                    try {
                      const input = {
                        userID: userID,
                      };
                      const resp = await mutate({
                        variables: {input: input},
                      });

                      if (resp) {
                        await fetch(resp.generateProfilePictureUrl.putUrl, {
                          method: 'PUT',
                          headers: {
                            'Content-Disposition': `attachment; filename=${displayName}-profile.jpg`,
                            ContentEncoding: 'base64',
                            ContentType: 'image/jpg',
                          },
                          body: image,
                        });
                        url = resp.generateProfilePictureUrl.getUrl;
                      } else {
                        alert('2');
                      }
                      setUrl(url);
                    } catch (error) {
                      alert(error);
                    }
                    setLoadingImage(false);
                  }}
                />
              </Box>
            </>
          </Modal>
        </IconButton>
        <label htmlFor="contained-button-file">
          <AddAPhotoIcon
            className={classes.addPhoto}
            aria-label="Edit"
            fontSize="small"
            color="primary"
          />
        </label>
      </Box>
    </Box>
  );
}
