/* tslint:disable */
/* eslint-disable */

import {ConcreteRequest} from 'relay-runtime';
export type ApplyForLeavePageQueryVariables = {};
export type ApplyForLeavePageQueryResponse = {
  readonly viewer: {
    readonly leaveTypes: ReadonlyArray<{
      readonly id: string;
      readonly description: string;
    }>;
  };
};
export type ApplyForLeavePageQuery = {
  readonly response: ApplyForLeavePageQueryResponse;
  readonly variables: ApplyForLeavePageQueryVariables;
};

/*
query ApplyForLeavePageQuery {
  viewer {
    leaveTypes {
      id
      description
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
    {
      alias: null,
      args: null,
      concreteType: 'Viewer',
      kind: 'LinkedField',
      name: 'viewer',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: 'LeaveType',
          kind: 'LinkedField',
          name: 'leaveTypes',
          plural: true,
          selections: [
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'id',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'description',
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      storageKey: null,
    },
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'ApplyForLeavePageQuery',
      selections: v0 /*: any*/,
      type: 'Query',
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'ApplyForLeavePageQuery',
      selections: v0 /*: any*/,
    },
    params: {
      id: null,
      metadata: {},
      name: 'ApplyForLeavePageQuery',
      operationKind: 'query',
      text:
        'query ApplyForLeavePageQuery {\n  viewer {\n    leaveTypes {\n      id\n      description\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = '8416bbca0662b1c0dcfe9c25ed74b497';
export default node;
