/* tslint:disable */
/* eslint-disable */

import {ReaderFragment} from 'relay-runtime';
import {FragmentRefs} from 'relay-runtime';
export type TimesheetsPage_timesheets = {
  readonly id: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly mobileNumber: string | null;
  readonly timesheets: {
    readonly total: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string | null;
        readonly totalActualHours: number | null;
        readonly userId: string | null;
        readonly lines: ReadonlyArray<{
          readonly id: string | null;
          readonly actualHours: number | null;
          readonly description: string | null;
          readonly position: number;
          readonly userTimesheetId: string | null;
        }>;
        readonly timesheet: {
          readonly id: string;
          readonly billableHours: number;
          readonly description: string;
          readonly name: string;
          readonly startDate: string;
          readonly endDate: string;
        };
      };
    }>;
  };
  readonly ' $refType': 'TimesheetsPage_timesheets';
};
export type TimesheetsPage_timesheets$data = TimesheetsPage_timesheets;
export type TimesheetsPage_timesheets$key = {
  readonly ' $data'?: TimesheetsPage_timesheets$data;
  readonly ' $fragmentRefs': FragmentRefs<'TimesheetsPage_timesheets'>;
};

const node: ReaderFragment = (function () {
  var v0 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v1 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'description',
      storageKey: null,
    };
  return {
    argumentDefinitions: [
      {
        kind: 'RootArgument',
        name: 'search',
        type: 'String',
      },
      {
        kind: 'RootArgument',
        name: 'first',
        type: 'Int',
      },
      {
        kind: 'RootArgument',
        name: 'after',
        type: 'String',
      },
    ],
    kind: 'Fragment',
    metadata: {
      refetch: {
        connection: null,
        fragmentPathInResult: ['node'],
        operation: require('./TimesheetsPageRefetchQuery.graphql.ts'),
        identifierField: 'id',
      },
    },
    name: 'TimesheetsPage_timesheets',
    selections: [
      v0 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'firstName',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'lastName',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'email',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'mobileNumber',
        storageKey: null,
      },
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'after',
            variableName: 'after',
          },
          {
            kind: 'Variable',
            name: 'first',
            variableName: 'first',
          },
          {
            kind: 'Variable',
            name: 'q',
            variableName: 'search',
          },
          {
            kind: 'Literal',
            name: 'sort',
            value: {
              direction: 'DESCENDING',
              field: 'ID',
            },
          },
        ],
        concreteType: 'UserTimesheetConnection',
        kind: 'LinkedField',
        name: 'timesheets',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'total',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'UserTimesheetConnectionEdge',
            kind: 'LinkedField',
            name: 'edges',
            plural: true,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: 'UserTimesheet',
                kind: 'LinkedField',
                name: 'node',
                plural: false,
                selections: [
                  v0 /*: any*/,
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'totalActualHours',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'userId',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    concreteType: 'UserTimesheetLine',
                    kind: 'LinkedField',
                    name: 'lines',
                    plural: true,
                    selections: [
                      v0 /*: any*/,
                      {
                        alias: null,
                        args: null,
                        kind: 'ScalarField',
                        name: 'actualHours',
                        storageKey: null,
                      },
                      v1 /*: any*/,
                      {
                        alias: null,
                        args: null,
                        kind: 'ScalarField',
                        name: 'position',
                        storageKey: null,
                      },
                      {
                        alias: null,
                        args: null,
                        kind: 'ScalarField',
                        name: 'userTimesheetId',
                        storageKey: null,
                      },
                    ],
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    concreteType: 'Timesheet',
                    kind: 'LinkedField',
                    name: 'timesheet',
                    plural: false,
                    selections: [
                      v0 /*: any*/,
                      {
                        alias: null,
                        args: null,
                        kind: 'ScalarField',
                        name: 'billableHours',
                        storageKey: null,
                      },
                      v1 /*: any*/,
                      {
                        alias: null,
                        args: null,
                        kind: 'ScalarField',
                        name: 'name',
                        storageKey: null,
                      },
                      {
                        alias: null,
                        args: null,
                        kind: 'ScalarField',
                        name: 'startDate',
                        storageKey: null,
                      },
                      {
                        alias: null,
                        args: null,
                        kind: 'ScalarField',
                        name: 'endDate',
                        storageKey: null,
                      },
                    ],
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ],
    type: 'User',
  };
})();
(node as any).hash = '94f39eb59e79af66bc98da0b2db56286';
export default node;
