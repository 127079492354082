import React, {useState} from 'react';
import {Box, Card, CardContent, Typography} from '@material-ui/core';
import {
  FormikStep,
  FormikStepper,
} from '../../modules/FormikStepper/FormikStepper';
import {FDropdown} from '../../modules/FMaterial/FDropdown/FDropdown';
import * as yup from 'yup';
import {useNavigate} from 'react-router';
import graphql from 'babel-plugin-relay/macro';
import {useMutation, useQuery} from 'relay-hooks/lib';
import {ApplyForLeavePageQuery} from '../../__generated__/ApplyForLeavePageQuery.graphql';
import {makeStyles} from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {ErrorMessage} from 'formik';
import moment from 'moment';
import {ApplyForLeavePageDaysMutation} from '../../__generated__/ApplyForLeavePageDaysMutation.graphql';
import {Toast} from '../../modules/Toast';
import {ApplyForLeavePageMutation} from '../../__generated__/ApplyForLeavePageMutation.graphql';

const useStyles = makeStyles(() => ({
  errorMessage: {
    color: '#f44336',
    fontFamily: 'Montserrat,Roboto',
    fontSize: '0.75rem',
  },
}));

const validationSchema = yup.object().shape({
  leaveType: yup.string().required('This field is required'),
  startDate: yup.string().required('This field is required'),
  endDate: yup.string().required('This field is required'),
});

const query = graphql`
  query ApplyForLeavePageQuery {
    viewer {
      leaveTypes {
        id
        description
      }
    }
  }
`;

interface CalculatedDaysInterface {
  daysBetween: number;
  decisions: readonly string[];
}

export function ApplyForLeavePage() {
  const classes = useStyles();
  const navigate = useNavigate();

  const tomorrow = new Date().setDate(new Date().getDate() + 1);

  const [calculatedDays, setCalculatedDays] = useState<
    CalculatedDaysInterface
  >();

  const [calculateLeaveDaysMutation] = useMutation<
    ApplyForLeavePageDaysMutation
  >(
    graphql`
      mutation ApplyForLeavePageDaysMutation($input: CalculateLeaveDaysInput!) {
        calculateLeaveDays(input: $input) {
          daysBetween
          decisions
        }
      }
    `
  );

  const [applyForLeaveMutation] = useMutation<ApplyForLeavePageMutation>(
    graphql`
      mutation ApplyForLeavePageMutation(
        $input: RequestLeaveInput!
        $search: String
        $first: Int
        $after: String
        $sort: LeaveSort
      ) {
        requestLeave(input: $input) {
          clientMutationId
          user {
            ...Header_userInformation
            ...TaskPage_tasks
            ...LeavesPage_leaves
          }
        }
      }
    `
  );

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [leaveType, setLeaveType] = useState<string | null>(null);

  const {props} = useQuery<ApplyForLeavePageQuery>(query);

  if (!props) {
    return <></>;
  }

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      flexDirection="column"
      pl={5}
    >
      <Card style={{width: '100%', boxShadow: 'none'}}>
        <CardContent>
          <FormikStepper
            enableReinitialize={true}
            validationSchema={validationSchema}
            initialValues={{
              leaveType: leaveType === null ? '' : leaveType,
              startDate:
                startDate === null
                  ? ''
                  : moment(startDate).format('YYYY-MM-DD'),
              endDate:
                endDate === null ? '' : moment(endDate).format('YYYY-MM-DD'),
            }}
            onSubmit={async (value) => {
              try {
                const response = await applyForLeaveMutation({
                  variables: {
                    input: {
                      typeId: value.leaveType,
                      startDate: value.startDate,
                      endDate: value.endDate,
                      days: calculatedDays?.daysBetween || 0,
                    },
                  },
                });

                if (response) {
                  // console.log(response);
                  navigate('/leaves');
                }
              } catch (e) {
                Toast('error', e.message);
              }
            }}
          >
            <FormikStep
              validationSchema={validationSchema}
              label="Leave Dates"
              toastMessage="Select your leave type and dates"
              handleFunction={async (value) => {
                try {
                  const response = await calculateLeaveDaysMutation({
                    variables: {
                      input: {
                        startDate: value.startDate,
                        endDate: value.endDate,
                      },
                    },
                  });

                  if (response) {
                    setCalculatedDays(response.calculateLeaveDays);
                  }
                } catch (error) {
                  Toast('error', error.message);
                  navigate('/leaves');
                }
              }}
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box width="600px" mr={5}>
                  <Box
                    px={2}
                    pb={2}
                    style={{boxSizing: 'border-box'}}
                    width="100%"
                  >
                    <FDropdown
                      field={{name: 'leaveType'}}
                      fullWidth
                      label={'Leave Type'}
                      data={props?.viewer.leaveTypes.map((leaveType) => {
                        return {
                          id: leaveType.id,
                          description: leaveType.description,
                        };
                      })}
                      onChange={(value) => {
                        setLeaveType(value.target.value);
                      }}
                    />
                  </Box>

                  <Box px={2} style={{boxSizing: 'border-box'}} width="100%">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        minDate={tomorrow}
                        helperText={
                          <>
                            <Typography className={classes.errorMessage}>
                              <ErrorMessage name="startDate" />
                            </Typography>
                            <Typography variant="caption">
                              This marks the date on which your leave will start
                            </Typography>
                          </>
                        }
                        fullWidth
                        variant="inline"
                        format="MM-dd-yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="Start Date (Inclusive)"
                        value={startDate}
                        autoOk={true}
                        onChange={(value: Date | null) => {
                          setStartDate(value);
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Box>

                  <Box
                    px={2}
                    pb={2}
                    style={{boxSizing: 'border-box'}}
                    width="100%"
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        minDate={tomorrow}
                        disableToolbar
                        helperText={
                          <>
                            <Typography className={classes.errorMessage}>
                              <ErrorMessage name="endDate" />
                            </Typography>
                            <Typography variant="caption">
                              This marks the date on which you will end
                            </Typography>
                          </>
                        }
                        fullWidth
                        variant="inline"
                        format="MM-dd-yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="End Date (Inclusive)"
                        value={endDate}
                        autoOk={true}
                        onChange={(value: Date | null) => {
                          setEndDate(value);
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Box>
                </Box>
              </Box>
            </FormikStep>

            <FormikStep label="Confirm" handleFunction={() => {}}>
              <Box display="flex" flexDirection="row" justifyContent={'center'}>
                <Box
                  mr={10}
                  display="flex"
                  flexDirection="column"
                  alignItems="left"
                >
                  <Typography variant="h6">You will require...</Typography>

                  <Typography>
                    {' '}
                    {`${calculatedDays?.daysBetween} working days off`}
                  </Typography>
                </Box>

                <Box display="flex" flexDirection="column" alignItems="left">
                  <Typography variant="h6">
                    How did we calculate your days off?
                  </Typography>
                  {calculatedDays?.decisions.map((decision) => {
                    return <Typography> {decision}</Typography>;
                  })}
                </Box>
              </Box>
            </FormikStep>
          </FormikStepper>
        </CardContent>
      </Card>
    </Box>
  );
}
