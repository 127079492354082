/* tslint:disable */
/* eslint-disable */

import {ConcreteRequest} from 'relay-runtime';
export type GenerateProfilePictureUrlInput = {
  clientMutationId?: string | null;
  userID: string;
};
export type UserProfilePictureMutationVariables = {
  input: GenerateProfilePictureUrlInput;
};
export type UserProfilePictureMutationResponse = {
  readonly generateProfilePictureUrl: {
    readonly putUrl: string;
    readonly getUrl: string;
  };
};
export type UserProfilePictureMutation = {
  readonly response: UserProfilePictureMutationResponse;
  readonly variables: UserProfilePictureMutationVariables;
};

/*
mutation UserProfilePictureMutation(
  $input: GenerateProfilePictureUrlInput!
) {
  generateProfilePictureUrl(input: $input) {
    putUrl
    getUrl
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'input',
        type: 'GenerateProfilePictureUrlInput!',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'input',
            variableName: 'input',
          },
        ],
        concreteType: 'GenerateProfilePictureUrlPayload',
        kind: 'LinkedField',
        name: 'generateProfilePictureUrl',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'putUrl',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'getUrl',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'UserProfilePictureMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'UserProfilePictureMutation',
      selections: v1 /*: any*/,
    },
    params: {
      id: null,
      metadata: {},
      name: 'UserProfilePictureMutation',
      operationKind: 'mutation',
      text:
        'mutation UserProfilePictureMutation(\n  $input: GenerateProfilePictureUrlInput!\n) {\n  generateProfilePictureUrl(input: $input) {\n    putUrl\n    getUrl\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'cc6136c064d2202ff286c2a1c0c50a6b';
export default node;
