import React, {useMemo} from 'react';
import Button from '@material-ui/core/Button';
import Dialog, {DialogProps} from '@material-ui/core/Dialog';
import DialogActions, {
  DialogActionsProps,
} from '@material-ui/core/DialogActions';
import DialogContent, {
  DialogContentProps,
} from '@material-ui/core/DialogContent';
import DialogContentText, {
  DialogContentTextProps,
} from '@material-ui/core/DialogContentText';
import DialogTitle, {DialogTitleProps} from '@material-ui/core/DialogTitle';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import * as yup from 'yup';
import {Formik} from 'formik';
import {FTextField} from '../FMaterial/FTextfield/FTextField';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      minWidth: '70vw',
      display: 'flex',
      justifyContent: 'center',
      borderRadius: '15px',
    },
    innerPaper: {
      maxWidth: '70vw',
      margin: '0 auto',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100vw',
      },
    },
    buttonSection: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      padding: theme.spacing(2),
      boxSizing: 'border-box',
    },
    buttonPadding: {
      padding: theme.spacing(1),
      maxWidth: '250px',
      flexBasis: '100px',
      flexGrow: 1,
    },
  })
);
interface MyDialog {
  title?: React.ReactNode;
  body?: React.ReactNode;
  dialogProps?: Omit<DialogProps, 'children'>;
  dialogTitleProps?: Omit<DialogTitleProps, 'children'>;
  dialogContentProps?: Omit<DialogContentProps, 'children'>;
  dialogContentTextProps?: Omit<DialogContentTextProps, 'children'>;
  dialogActionProps?: Omit<DialogActionsProps, 'children'>;
  handlePromptResponse: () => void;
  handleCancel?: () => void;
  secret?: string;
}
export interface DialogContextType {
  dismiss(): void;
  notify(dialog: MyDialog): void;
}
export const ConfirmDialog = React.createContext<DialogContextType>({
  dismiss: () => null,
  notify: () => null,
});

interface Props {
  children: React.ReactNode;
}

export function ConfirmDialogProvider({children}: Props) {
  const [open, setOpen] = React.useState(false);
  const [dialogState, setDialogState] = React.useState<MyDialog>();
  const classes = useStyles();

  const dismiss = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const notify = React.useCallback(
    (snack: MyDialog) => {
      setDialogState(snack);
      setOpen(true);
    },
    [setDialogState]
  );
  const value = useMemo(() => ({dismiss, notify}), [dismiss, notify]);
  const handleClose = () => setOpen(false);
  const validationSchema = yup.object().shape({
    input: yup
      .string()
      .test('testSecret', 'Your secret does not match', (value: string) => {
        return value === dialogState?.secret ?? 'CONFIRM';
      })
      .required()
      .trim(),
  });

  return (
    <ConfirmDialog.Provider value={value}>
      <Dialog
        PaperProps={{
          classes: {
            root: classes.paper,
          },
        }}
        open={open}
        {...dialogState?.dialogProps}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Box className={classes.innerPaper}>
          <Formik
            initialValues={{input: ''}}
            validationSchema={dialogState?.secret && validationSchema}
            onSubmit={() => {
              dialogState?.handlePromptResponse();
              setOpen(false);
            }}
          >
            {({submitForm}) => {
              return (
                <>
                  <DialogTitle
                    id="alert-dialog-slide-title"
                    {...dialogState?.dialogTitleProps}
                  >
                    {dialogState?.title ??
                      'Please confirm the following before your action can be completed'}
                  </DialogTitle>
                  <DialogContent
                    {...(dialogState && dialogState.dialogContentProps)}
                  >
                    <DialogContentText
                      id="alert-dialog-slide-description"
                      {...dialogState?.dialogContentTextProps}
                    >
                      {dialogState?.body ??
                        'Please insert CONFIRM to complete this action'}
                    </DialogContentText>
                    {dialogState?.secret && (
                      <FTextField
                        field={{name: 'input'}}
                        variant="outlined"
                        fullWidth
                        style={{maxWidth: '400px'}}
                        autoComplete="off"
                      />
                    )}
                  </DialogContent>
                  <DialogActions
                    {...dialogState?.dialogActionProps}
                    className={classes.buttonSection}
                  >
                    <Box className={classes.buttonPadding}>
                      <Button
                        fullWidth
                        color="primary"
                        variant="contained"
                        onClick={submitForm}
                      >
                        Confirm
                      </Button>
                    </Box>
                    <Box className={classes.buttonPadding}>
                      <Button
                        fullWidth
                        color="primary"
                        variant="text"
                        onClick={() => {
                          dialogState?.handleCancel &&
                            dialogState.handleCancel();
                          setOpen(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </DialogActions>
                </>
              );
            }}
          </Formik>
        </Box>
      </Dialog>
      {children}
    </ConfirmDialog.Provider>
  );
}
