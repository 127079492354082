/* tslint:disable */
/* eslint-disable */

import {ConcreteRequest} from 'relay-runtime';
export type AddEditAddressPageQueryVariables = {};
export type AddEditAddressPageQueryResponse = {
  readonly viewer: {
    readonly countries: ReadonlyArray<{
      readonly id: string;
      readonly description: string;
    }>;
    readonly provinces: ReadonlyArray<{
      readonly id: string;
      readonly description: string;
    }>;
  };
};
export type AddEditAddressPageQuery = {
  readonly response: AddEditAddressPageQueryResponse;
  readonly variables: AddEditAddressPageQueryVariables;
};

/*
query AddEditAddressPageQuery {
  viewer {
    countries {
      id
      description
    }
    provinces {
      id
      description
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'id',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'description',
        storageKey: null,
      },
    ],
    v1 = [
      {
        alias: null,
        args: null,
        concreteType: 'Viewer',
        kind: 'LinkedField',
        name: 'viewer',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: 'Country',
            kind: 'LinkedField',
            name: 'countries',
            plural: true,
            selections: v0 /*: any*/,
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'Province',
            kind: 'LinkedField',
            name: 'provinces',
            plural: true,
            selections: v0 /*: any*/,
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'AddEditAddressPageQuery',
      selections: v1 /*: any*/,
      type: 'Query',
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'AddEditAddressPageQuery',
      selections: v1 /*: any*/,
    },
    params: {
      id: null,
      metadata: {},
      name: 'AddEditAddressPageQuery',
      operationKind: 'query',
      text:
        'query AddEditAddressPageQuery {\n  viewer {\n    countries {\n      id\n      description\n    }\n    provinces {\n      id\n      description\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'c3f9ad62e61d88a1cb0512671946a0bb';
export default node;
