import React, {Suspense, useContext, useState} from 'react';
import {ErrorMessage, Form, Formik} from 'formik';
import {
  Box,
  Button,
  CircularProgress,
  Fab,
  Paper,
  Typography,
} from '@material-ui/core';
import FormItem from '../../atoms/FormItem';
import {FTextField} from '../../modules/FMaterial/FTextfield/FTextField';
import * as yup from 'yup';
import SaveIcon from '@material-ui/icons/Save';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import {ConfirmNewPassword} from '../../modules/NewPassword/NewPassword';
import {useFragment, useMutation, useQuery} from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';
import {useNavigate} from 'react-router';
import {useAuthContext} from '../../context/AuthContext';
import {ProfilePageQuery} from '../../__generated__/ProfilePageQuery.graphql';
import {Toast} from '../../modules/Toast';
import {UserProfilePicture} from '../../modules/UserProfilePicture/UserProfilePicture';
import {ProfilePageMutation} from '../../__generated__/ProfilePageMutation.graphql';
import AddEditAddressPage from '../AddEditAddressPage/AddEditAddressPage';
import {UserAddress} from '../../modules/UserAddress/UserAddress';
import {ProfilePage_user$key} from '../../__generated__/ProfilePage_user.graphql';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import {FDropdown} from '../../modules/FMaterial/FDropdown/FDropdown';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  errorMessage: {
    color: '#f44336',
    fontFamily: 'Montserrat,Roboto',
    fontSize: '0.75rem',
  },
}));

const signUpSchema = yup.object().shape({
  email: yup.string().email('Email not valid').required('Email is required.'),
  lastName: yup.string().required('Please enter your last name'),
  firstName: yup.string().required('Please enter your first name'),
  incomeTaxNumber: yup.string().required('Please enter your income tax number'),
  personalReferenceValue: yup.string().required('This field is required'),
  personalReferenceType: yup.string().required('This field is required'),
  dateOfBirth: yup.string().required('This field is required'),
  mobileNumber: yup.string(),
});

const query = graphql`
  query ProfilePageQuery {
    viewer {
      user {
        ...ProfilePage_user
      }
    }
  }
`;

const fragment = graphql`
  fragment ProfilePage_user on User
    @refetchable(queryName: "ProfilePageRefetchQuery") {
    displayName
    email
    firstName
    id
    lastName
    mobileNumber
    profileImageUri
    username
    dateOfBirth
    incomeTaxNumber
    personalReferenceType
    personalReferenceValue
    addresses {
      id
      building
      street
      city
      postcode
      province
      country
      preferred
      prettyFormat
    }
  }
`;

interface AddressProps {
  id: string | null;
  building: string | null;
  street: string | null;
  city: string | null;
  postcode: string | null;
  province: string | null;
  country: string | null;
  preferred: boolean;
}

interface Props {
  node: ProfilePage_user$key;
}

interface UserProps {
  email: string;
  displayName: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  incomeTaxNumber: string;
  personalReferenceValue: string;
  personalReferenceType: string;
  dateOfBirth: string;
  id: string;
  username: string;
  profileUri: string;
}

export function ProfilePage({node}: Props) {
  const classes = useStyles();

  const [mutation, {loading}] = useMutation<ProfilePageMutation>(graphql`
    mutation ProfilePageMutation(
      $input: UpdateUserInput!
      $search: String
      $first: Int
      $after: String
    ) {
      updateUser(input: $input) {
        user {
          ...Header_userInformation
          ...ProfilePage_user
        }
        processInstanceId
        clientMutationId
      }
    }
  `);
  const fragmentNode = useFragment(fragment, node);
  const newPassword = useContext(ConfirmNewPassword);
  const [imageUrl, setImageUrl] = React.useState('');
  const navigate = useNavigate();
  const {auth} = useAuthContext();
  const {props, error} = useQuery<ProfilePageQuery>(query);
  const [dateOfBirth, setDateOfBirth] = useState<Date | null>(
    new Date(fragmentNode.dateOfBirth ? fragmentNode.dateOfBirth : '')
  );

  const [editOpen, setEditOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<AddressProps>();

  const [action, setAction] = useState('ADD');

  const ADD_ADDRESS_ACTION = 'ADD';
  const EDIT_ADDRESS_ACTION = 'EDIT';

  function editAddress(data: AddressProps) {
    setSelectedAddress(data);
    setEditOpen(true);
    setAction(EDIT_ADDRESS_ACTION);
  }

  function addAddress() {
    setSelectedAddress({
      id: null,
      building: null,
      street: null,
      city: null,
      postcode: null,
      province: null,
      country: null,
      preferred: false,
    });
    setEditOpen(true);
    setAction(ADD_ADDRESS_ACTION);
  }

  React.useEffect(() => {
    if (!auth.authenticated) {
      navigate('/login');
    }
  }, [navigate, auth.authenticated]);
  if (error) {
    Toast('error', error);
  }
  if (!props) {
    return <CircularProgress />;
  }

  if (!auth.authenticated) {
    return <></>;
  }

  return (
    <Box display="flex" pl={5} justifyContent="center">
      <Formik<UserProps>
        initialValues={{
          email: fragmentNode.email || '',
          displayName: fragmentNode.displayName || '',
          firstName: fragmentNode.firstName || '',
          id: fragmentNode.id || '',
          lastName: fragmentNode.lastName || '',
          mobileNumber: fragmentNode.mobileNumber || '',
          username: fragmentNode.username || '',
          profileUri: fragmentNode.profileImageUri || '',
          incomeTaxNumber: fragmentNode.incomeTaxNumber || '',
          personalReferenceValue: fragmentNode.personalReferenceValue || '',
          personalReferenceType: fragmentNode.personalReferenceType || '',
          dateOfBirth: fragmentNode.dateOfBirth || '',
        }}
        validationSchema={signUpSchema}
        onSubmit={async ({
          username,
          mobileNumber,
          lastName,
          id,
          firstName,
          displayName,
          email,
          profileUri,
          incomeTaxNumber,
          dateOfBirth,
          personalReferenceType,
          personalReferenceValue,
        }) => {
          try {
            const response = await mutation({
              variables: {
                input: {
                  email: email,
                  displayName: displayName,
                  firstName: firstName,
                  id: id,
                  mobileNumber: mobileNumber,
                  lastName: lastName,
                  username: username,
                  profileImageUri: imageUrl ? imageUrl : profileUri,
                  incomeTaxNumber: incomeTaxNumber || '',
                  personalReferenceValue: personalReferenceValue || '',
                  personalReferenceType: personalReferenceType || '',
                  dateOfBirth: dateOfBirth || '',
                },
              },
            });
            if (response) {
              Toast('success', 'Successfully updated profile');
            }
          } catch (error) {
            Toast('error', error);
          }
        }}
      >
        {({submitForm, setFieldValue}) => {
          return (
            <Box width="100%">
              <Form
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
                onKeyDown={(event) => {
                  if (event.keyCode === 13) {
                    event.preventDefault();
                    submitForm();
                  }
                }}
              >
                <Box display="flex" flexDirection="column" width="500px">
                  <Box pb={3} textAlign="center" maxWidth="100%">
                    <Typography variant="h5">Edit Profile</Typography>
                  </Box>
                  <Suspense fallback="Loading..">
                    <UserProfilePicture
                      userID={fragmentNode.id || ''}
                      image={fragmentNode.profileImageUri}
                      displayName={fragmentNode.displayName || ''}
                      setUrl={(url) => {
                        setImageUrl(url);
                        submitForm();
                      }}
                    />
                  </Suspense>
                  <FormItem>
                    <FTextField
                      fullWidth
                      field={{name: 'username'}}
                      label="Username"
                      disabled
                    />
                  </FormItem>
                  <FormItem>
                    <FTextField
                      fullWidth
                      field={{name: 'firstName'}}
                      label="First Name"
                    />
                  </FormItem>
                  <FormItem>
                    <FTextField
                      fullWidth
                      field={{name: 'lastName'}}
                      label="Last Name"
                    />
                  </FormItem>

                  <FormItem>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        helperText={
                          <Typography className={classes.errorMessage}>
                            <ErrorMessage name="dateOfBirth" />
                          </Typography>
                        }
                        fullWidth
                        variant="inline"
                        format="yyyy-MM-dd"
                        margin="normal"
                        id="date-picker-inline"
                        label="Date of Birth (YYYY-MM-DD)"
                        value={dateOfBirth}
                        autoOk={true}
                        onChange={(value: Date | null) => {
                          setFieldValue(
                            'dateOfBirth',
                            moment(value).format('YYYY-MM-DD')
                          );
                          setDateOfBirth(value);
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </FormItem>

                  <FormItem>
                    <FDropdown
                      field={{name: 'personalReferenceType'}}
                      fullWidth
                      label={'Personal Reference Type'}
                      data={[
                        {
                          id: 'SA',
                          description: 'South African ID',
                        },
                        {
                          id: 'P',
                          description: 'Passport',
                        },
                        {
                          id: 'OTH',
                          description: 'Other',
                        },
                      ]}
                    />
                  </FormItem>

                  <FormItem>
                    <FTextField
                      fullWidth
                      field={{name: 'personalReferenceValue'}}
                      label="Personal Reference Value"
                    />
                  </FormItem>

                  <FormItem>
                    <FTextField
                      fullWidth
                      field={{name: 'incomeTaxNumber'}}
                      label="Income Tax Number"
                    />
                  </FormItem>
                  {/*<FormItem>*/}
                  {/*  <FTextField*/}
                  {/*    fullWidth*/}
                  {/*    field={{name: 'displayName'}}*/}
                  {/*    label="Display Name"*/}
                  {/*  />*/}
                  {/*</FormItem>*/}
                  <FormItem>
                    <FTextField
                      fullWidth
                      field={{name: 'email'}}
                      label="Primary E-mail Address"
                    />
                  </FormItem>
                  <FormItem>
                    <FTextField
                      fullWidth
                      field={{name: 'mobileNumber'}}
                      label="Cellphone / Telephone Number"
                    />
                  </FormItem>

                  <FormItem>
                    <UserAddress
                      data={fragmentNode.addresses}
                      editAddress={editAddress}
                    />
                  </FormItem>
                  {fragmentNode.addresses.length === 0 ? (
                    <FormItem>
                      <Paper
                        style={{
                          borderRadius: '10px',
                          marginBottom: '12px',
                          minHeight: '115px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Box>
                          <Button
                            color="secondary"
                            onClick={() => {
                              addAddress();
                            }}
                          >
                            Add Address
                          </Button>
                        </Box>
                      </Paper>
                    </FormItem>
                  ) : null}
                </Box>
              </Form>
              <Box
                display="flex"
                flexDirection="column-reverse"
                pb={2}
                position="fixed"
                bottom="0"
                right="16px"
              >
                <Fab
                  style={{margin: '5px', width: 80}}
                  variant="extended"
                  color="primary"
                  onClick={() => {
                    submitForm();
                  }}
                >
                  {loading ? (
                    <CircularProgress size={26} style={{color: 'black'}} />
                  ) : (
                    <SaveIcon />
                  )}
                </Fab>

                <Fab
                  style={{margin: '5px', width: 80}}
                  variant="extended"
                  onClick={() => {
                    newPassword.notify({
                      userName: fragmentNode.email,
                    });
                  }}
                >
                  <LockOpenIcon />
                </Fab>
              </Box>
            </Box>
          );
        }}
      </Formik>

      {editOpen ? (
        <AddEditAddressPage
          open={editOpen}
          firstAddress={
            fragmentNode.addresses.length === 0 || selectedAddress?.preferred
              ? true
              : false
          }
          close={() => setEditOpen(!editOpen)}
          data={selectedAddress}
          action={action}
        />
      ) : null}
    </Box>
  );
}

export const ProfilePageWrapper = () => {
  const {props} = useQuery(query);
  if (!props) {
    return <> </>;
  }
  return <ProfilePage node={props.viewer.user} />;
};
