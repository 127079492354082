import * as React from 'react';
import {ThemeProvider} from '@material-ui/core';
import {theme} from '../theme/Theme';

interface Props {
  children: React.ReactNode;
}
export function ThemeController({children}: Props) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
