/* tslint:disable */
/* eslint-disable */

import {ReaderFragment} from 'relay-runtime';
import {FragmentRefs} from 'relay-runtime';
export type Header_userInformation = {
  readonly firstName: string;
  readonly profileImageUri: string;
  readonly tasks: {
    readonly total: number;
  };
  readonly ' $refType': 'Header_userInformation';
};
export type Header_userInformation$data = Header_userInformation;
export type Header_userInformation$key = {
  readonly ' $data'?: Header_userInformation$data;
  readonly ' $fragmentRefs': FragmentRefs<'Header_userInformation'>;
};

const node: ReaderFragment = {
  argumentDefinitions: [
    {
      kind: 'RootArgument',
      name: 'search',
      type: 'String',
    },
    {
      kind: 'RootArgument',
      name: 'first',
      type: 'Int',
    },
    {
      kind: 'RootArgument',
      name: 'after',
      type: 'String',
    },
  ],
  kind: 'Fragment',
  metadata: null,
  name: 'Header_userInformation',
  selections: [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'firstName',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'profileImageUri',
      storageKey: null,
    },
    {
      alias: null,
      args: [
        {
          kind: 'Variable',
          name: 'after',
          variableName: 'after',
        },
        {
          kind: 'Variable',
          name: 'first',
          variableName: 'first',
        },
        {
          kind: 'Variable',
          name: 'q',
          variableName: 'search',
        },
      ],
      concreteType: 'TaskConnection',
      kind: 'LinkedField',
      name: 'tasks',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'total',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
  ],
  type: 'User',
};
(node as any).hash = '516ce2c34e25671814be8f7edc6672b7';
export default node;
