import React, {useState} from 'react';
import {
  Box,
  Drawer,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  CircularProgress,
  Button,
  Switch,
} from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import {Formik, Form} from 'formik';
import {FTextField} from '../../modules/FMaterial/FTextfield/FTextField';
import FormItem from '../../atoms/FormItem';
import graphql from 'babel-plugin-relay/macro';
import {useMutation} from 'relay-hooks/lib';
import {AddEditAddressPageMutation} from '../../__generated__/AddEditAddressPageMutation.graphql';
import {Toast} from '../../modules/Toast';
import {FDropdown} from '../../modules/FMaterial/FDropdown/FDropdown';
import * as yup from 'yup';
import {useQuery} from 'relay-hooks';
import {AddEditAddressPageQuery} from '../../__generated__/AddEditAddressPageQuery.graphql';

const validationSchema = yup.object().shape({
  street: yup.string().required('This field is required'),
  city: yup.string().required('This field is required'),
  postcode: yup.string().required('This field is required'),
  province: yup.string().required('This field is required'),
  country: yup.string().required('This field is required'),
});

type AddressProp =
  | {
      id: string;
      building: string;
      street: string;
      city: string;
      postcode: string;
      province: string;
      country: string;
      preferred: boolean;
    }
  | any;

interface Props {
  firstAddress: boolean;
  action: string;
  open: boolean;
  close: () => void;
  data: AddressProp;
}

const query = graphql`
  query AddEditAddressPageQuery {
    viewer {
      countries {
        id
        description
      }

      provinces {
        id
        description
      }
    }
  }
`;

function AddEditAddressPage({close, firstAddress, open, data, action}: Props) {
  const [mutation, {loading}] = useMutation<AddEditAddressPageMutation>(
    graphql`
      mutation AddEditAddressPageMutation($input: SaveAddressInput!) {
        saveAddress(input: $input) {
          clientMutationId
          user {
            id
            ...ProfilePage_user
          }
        }
      }
    `
  );

  const switchInitialState = firstAddress || data.preferred ? true : false;

  const [pref, setPreferred] = useState(switchInitialState);

  const {props} = useQuery<AddEditAddressPageQuery>(query);

  function prefHandler(value: boolean) {
    setPreferred(value);
  }

  function handleOnClose() {
    setPreferred(false);
    close();
  }

  return (
    <Box>
      <Drawer open={open} onClose={() => handleOnClose} anchor="right">
        <AppBar
          position="sticky"
          style={{
            backgroundColor: 'white',
            borderBottom: '1px solid lightgrey',
          }}
        >
          <Toolbar>
            <Box display="flex" justifyContent="space-between" width="100%">
              <Box pl={'11px'}>
                <Typography variant="h5">
                  {action === 'ADD' ? 'Add Address' : 'Edit Address'}
                </Typography>
              </Box>
              <IconButton
                style={{
                  backgroundColor: '#F2F8FA',
                  width: '40px',
                  height: '28px',
                  borderRadius: '100px',
                  marginLeft: '10px',
                }}
                onClick={() => {
                  handleOnClose();
                }}
              >
                <CloseRoundedIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        <Box style={{margin: '15px'}}>
          <Formik<AddressProp>
            validationSchema={validationSchema}
            initialValues={
              action === 'ADD'
                ? {
                    id: null,
                    building: '',
                    street: '',
                    city: '',
                    postcode: '',
                    province: '',
                    country: 'SOUTH AFRICA',
                  }
                : {
                    id: data.id,
                    building: data.building,
                    street: data.street,
                    city: data.city,
                    postcode: data.postcode,
                    province: data.province,
                    country: data.country,
                  }
            }
            onSubmit={async ({
              id,
              building,
              street,
              city,
              postcode,
              province,
              country,
            }) => {
              // alert(pref);

              const input = {
                id,
                building,
                street,
                city,
                postcode,
                province,
                country,
                preferred: pref,
              };

              try {
                const response = await mutation({
                  variables: {
                    input: input,
                  },
                });

                if (response) {
                  Toast('success', 'Successfully updated profile');

                  handleOnClose();
                }
              } catch (error) {
                Toast('error', error);
              }
            }}
          >
            {({submitForm}) => {
              return (
                <Box width="100%" display="flex" flexDirection="row">
                  <Form
                    style={{display: 'flex', justifyContent: 'center'}}
                    onKeyDown={(event) => {
                      if (event.keyCode === 13) {
                        event.preventDefault();
                        submitForm();
                      }
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      width="500px"
                      pb={10}
                    >
                      <FormItem>
                        <FTextField
                          fullWidth
                          field={{name: 'building'}}
                          label="Building"
                        />
                      </FormItem>

                      <FormItem>
                        <FTextField
                          fullWidth
                          field={{name: 'street'}}
                          label="Street"
                        />
                      </FormItem>

                      <FormItem>
                        <FTextField
                          fullWidth
                          field={{name: 'city'}}
                          label="City"
                        />
                      </FormItem>

                      <FormItem>
                        <FTextField
                          fullWidth
                          field={{name: 'postcode'}}
                          label="Post Code"
                        />
                      </FormItem>
                      <FormItem>
                        <FDropdown
                          field={{name: 'province'}}
                          fullWidth
                          label="Province"
                          data={props?.viewer.provinces.map((province) => {
                            return {
                              id: province.description,
                              description: province.description,
                            };
                          })}
                        />
                      </FormItem>

                      <FormItem>
                        <FDropdown
                          field={{name: 'country'}}
                          fullWidth
                          label="Country"
                          data={props?.viewer.countries.map((country) => {
                            return {
                              id: country.description,
                              description: country.description,
                            };
                          })}
                        />
                      </FormItem>

                      <FormItem>
                        <Switch
                          disabled={firstAddress}
                          color="primary"
                          checked={pref}
                          onChange={() => prefHandler(!pref)}
                        />
                        Preferred
                      </FormItem>

                      <FormItem pt={2}>
                        <Button
                          color="primary"
                          fullWidth
                          onClick={() => {
                            submitForm();
                          }}
                        >
                          {loading ? (
                            <CircularProgress
                              size={26}
                              style={{color: 'black'}}
                            />
                          ) : (
                            'Save'
                          )}
                        </Button>
                      </FormItem>
                    </Box>
                  </Form>
                </Box>
              );
            }}
          </Formik>
        </Box>
      </Drawer>
    </Box>
  );
}

export default AddEditAddressPage;
