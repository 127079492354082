import React, {useMemo} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import * as yup from 'yup';
import {Formik} from 'formik';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import {FTextFieldPassword} from '../FMaterial/FTextfield/FTextFieldPassword';
import graphql from 'babel-plugin-relay/macro';
import {useMutation} from 'relay-hooks';
import {Toast} from '../Toast';
import {CircularProgress} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display: 'flex',
      justifyContent: 'center',
      borderRadius: '15px',
    },
    innerPaper: {
      maxWidth: '350px',
      minWidth: '20vw',
      margin: '0 auto',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100vw',
      },
    },
    buttonSection: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      padding: theme.spacing(2),
      boxSizing: 'border-box',
    },
    buttonPadding: {
      padding: theme.spacing(1),
      maxWidth: '250px',
      flexBasis: '100px',
      flexGrow: 1,
    },
  })
);
interface NewPasswordProps {
  handlePromptResponse?: () => void;
  handleCancel?: () => void;
  userName?: string;
}
export interface DialogContextType {
  notify(password: NewPasswordProps): void;
}
export const ConfirmNewPassword = React.createContext<DialogContextType>({
  notify: () => null,
});

interface Props {
  children: React.ReactNode;
}

interface FormikValues {
  newPassword: string;
  currentPassword: string;
  confirmNewPassword: string;
  username: string;
}

export function NewPassword({children}: Props) {
  const [open, setOpen] = React.useState(false);
  const [dialogState, setDialogState] = React.useState<NewPasswordProps>();
  const [mutation, {loading}] = useMutation(graphql`
    mutation NewPasswordMutation($input: ChangePasswordInput!) {
      changePassword(input: $input) {
        clientMutationId
      }
    }
  `);
  const classes = useStyles();
  const dismiss = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const notify = React.useCallback((snack: NewPasswordProps) => {
    setDialogState(snack);
    setOpen(true);
  }, []);
  const value = useMemo(() => ({dismiss, notify}), [dismiss, notify]);
  const handleClose = () => setOpen(false);
  const validationSchema = yup.object().shape({
    newPassword: yup
      .string()
      .required('This field is required')
      .min(5, 'Please make sure your password has a minimum of 5 characters'),
    confirmNewPassword: yup
      .string()
      .oneOf([yup.ref('newPassword'), undefined], "Passwords don't match")
      .required('This field is required'),
    currentPassword: yup.string().required('This field is required'),
  });

  return (
    <ConfirmNewPassword.Provider value={value}>
      <Dialog
        PaperProps={{
          classes: {
            root: classes.paper,
          },
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Box className={classes.innerPaper}>
          <Formik<FormikValues>
            initialValues={{
              newPassword: '',
              currentPassword: '',
              confirmNewPassword: '',
              username: dialogState?.userName || '',
            }}
            validationSchema={validationSchema}
            onSubmit={({currentPassword, newPassword}) => {
              try {
                const response = mutation({
                  variables: {
                    input: {
                      username: dialogState?.userName || '',
                      newPlainTextPassword: newPassword,
                      currentPlainTextPassword: currentPassword,
                    },
                  },
                });
                if (response) {
                  response.then(
                    () => {
                      Toast('success', 'Successfully updated your password');
                      setOpen(false);
                    },
                    () => {
                      Toast(
                        'warning',
                        'Please make sure that the current password is correct'
                      );
                    }
                  );
                }
              } catch (error) {
                Toast('error', error);
              }
            }}
          >
            {({submitForm}) => {
              return (
                <>
                  <DialogTitle id="alert-dialog-slide-title">
                    <Box display="flex" alignItems="center">
                      <LockOpenIcon />
                      Change Password
                    </Box>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      <FTextFieldPassword
                        style={{marginTop: '10px'}}
                        fullWidth
                        field={{
                          name: 'currentPassword',
                        }}
                        type="password"
                        label="Current Password"
                      />
                      <FTextFieldPassword
                        style={{marginTop: '10px'}}
                        fullWidth
                        field={{
                          name: 'newPassword',
                        }}
                        type="password"
                        label="New Password"
                      />
                      <FTextFieldPassword
                        style={{marginTop: '10px'}}
                        fullWidth
                        field={{
                          name: 'confirmNewPassword',
                        }}
                        type="password"
                        label="Confirm New Password"
                      />
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions className={classes.buttonSection}>
                    <Box className={classes.buttonPadding}>
                      <Button
                        fullWidth
                        color="primary"
                        variant="contained"
                        disabled={!!loading}
                        onClick={submitForm}
                      >
                        {loading ? (
                          <CircularProgress
                            size={26}
                            style={{color: 'white'}}
                          />
                        ) : (
                          'Confirm'
                        )}
                      </Button>
                    </Box>
                    <Box className={classes.buttonPadding}>
                      <Button
                        fullWidth
                        color="primary"
                        variant="text"
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </DialogActions>
                </>
              );
            }}
          </Formik>
        </Box>
      </Dialog>
      {children}
    </ConfirmNewPassword.Provider>
  );
}
