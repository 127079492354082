/* tslint:disable */
/* eslint-disable */

import {ConcreteRequest} from 'relay-runtime';
export type ResetPasswordInput = {
  clientMutationId?: string | null;
  activationSerialNumber: string;
  plainTextPassword: string;
  confirmPlainTextPassword: string;
};
export type ResetPasswordPageMutationVariables = {
  input: ResetPasswordInput;
};
export type ResetPasswordPageMutationResponse = {
  readonly resetPassword: {
    readonly processInstanceId: string;
  };
};
export type ResetPasswordPageMutation = {
  readonly response: ResetPasswordPageMutationResponse;
  readonly variables: ResetPasswordPageMutationVariables;
};

/*
mutation ResetPasswordPageMutation(
  $input: ResetPasswordInput!
) {
  resetPassword(input: $input) {
    processInstanceId
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'input',
        type: 'ResetPasswordInput!',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'input',
            variableName: 'input',
          },
        ],
        concreteType: 'ResetPasswordPayload',
        kind: 'LinkedField',
        name: 'resetPassword',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'processInstanceId',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'ResetPasswordPageMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'ResetPasswordPageMutation',
      selections: v1 /*: any*/,
    },
    params: {
      id: null,
      metadata: {},
      name: 'ResetPasswordPageMutation',
      operationKind: 'mutation',
      text:
        'mutation ResetPasswordPageMutation(\n  $input: ResetPasswordInput!\n) {\n  resetPassword(input: $input) {\n    processInstanceId\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'a09f4996d619f93a668b5cb79ec59bbc';
export default node;
