import React, {useState} from 'react';
import {Form, Formik, FormikConfig, FormikValues} from 'formik';
import {Toast} from '../Toast';
import {
  Button,
  CircularProgress,
  Grid,
  Step,
  StepLabel,
  Stepper,
} from '@material-ui/core';
import {useAuthContext} from '../../context/AuthContext';

export interface FormikStepProps
  extends Pick<
    FormikConfig<FormikValues>,
    'children' | 'validationSchema' | 'enableReinitialize'
  > {
  label: string;
  value?: number;
  toastMessage?: string;
  handleFunction: (value: any) => void;
}

export function FormikStep({children}: FormikStepProps) {
  return <>{children}</>;
}

export function FormikStepper({
  children,
  ...props
}: FormikConfig<FormikValues>) {
  const childrenArray = React.Children.toArray(children) as React.ReactElement<
    FormikStepProps
  >[];
  const [step, setStep] = useState(0);
  const currentChild = childrenArray[step];
  const {auth} = useAuthContext();
  const [completed, setCompleted] = useState(false);
  const handleBack = () => {
    setStep((prevActiveStep) => prevActiveStep - 1);
  };

  function isLastStep() {
    return step === childrenArray.length - 1;
  }

  return (
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={async (values, helpers) => {
        if (isLastStep() && auth.authenticated) {
          await props.onSubmit(values, helpers);
          setCompleted(true);
        } else if (currentChild.props.value === 0) {
          Toast('error', currentChild.props.toastMessage);
        } else {
          currentChild.props.handleFunction(values);
          setStep((s) => s + 1);
        }
      }}
    >
      {({isSubmitting}) => (
        <Form autoComplete="off">
          <Grid
            container
            spacing={2}
            style={{display: 'flex', justifyContent: 'flex-end'}}
          >
            {step > 0 ? (
              <Grid item>
                <Button
                  disabled={isSubmitting}
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    handleBack();
                  }}
                >
                  Back
                </Button>
              </Grid>
            ) : null}
            <Grid item>
              <Button
                startIcon={
                  isSubmitting ? <CircularProgress size="1rem" /> : null
                }
                disabled={isSubmitting}
                variant="contained"
                color="primary"
                type="submit"
              >
                {isSubmitting ? 'Submitting' : isLastStep() ? 'Submit' : 'Next'}
              </Button>
            </Grid>
          </Grid>
          <Stepper
            alternativeLabel
            activeStep={step}
            style={{fontSize: '150px'}}
          >
            {childrenArray.map((child, index) => (
              <Step
                style={{fontSize: '150px'}}
                key={child.props.label}
                completed={step > index || completed}
              >
                <StepLabel>{child.props.label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {currentChild}
        </Form>
      )}
    </Formik>
  );
}
