import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import * as yup from 'yup';
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core';
import graphql from 'babel-plugin-relay/macro';
import {useMutation} from 'relay-hooks/lib';
import {Formik, FormikValues} from 'formik';
import {FTextField} from '../FMaterial/FTextfield/FTextField';
import {EditTimesheetMutation} from '../../__generated__/EditTimesheetMutation.graphql';
import {Toast} from '../Toast';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display: 'flex',
      justifyContent: 'center',
      borderRadius: '15px',
    },
    innerPaper: {
      maxWidth: '600px',
      minWidth: '28vw',
      margin: '0 auto',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100vw',
      },
    },
    buttonSection: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      padding: theme.spacing(2),
      boxSizing: 'border-box',
    },
    buttonPadding: {
      padding: theme.spacing(1),
      maxWidth: '250px',
      flexBasis: '100px',
      flexGrow: 1,
    },
    deleteButton: {
      marginRight: '20%',
      // backgroundColor: "red"
    },
  })
);

const lineValidationShape: any = {};
const initialValues: any = {};

interface UserTimesheetLineInterface {
  id: string | null;
  userTimesheetId: string | null;
  position: number;
  description: string | null;
  actualHours: number | null;
}

interface TimesheetInterface {
  id: string;
  billableHours: number;
  description: string;
  name: string;
  startDate: string;
  endDate: string;
}

interface UserTimesheetInterface {
  id: string | null;
  totalActualHours: number | null;
  userId: string | null;
  lines: readonly UserTimesheetLineInterface[];
  timesheet: TimesheetInterface;
}

interface Props {
  close: () => void;
  cancel: () => void;
  userTimesheet: UserTimesheetInterface | null;
  open: boolean;
}

export function EditTimesheet({close, cancel, userTimesheet, open}: Props) {
  const classes = useStyles();

  const [mutation, {loading}] = useMutation<EditTimesheetMutation>(
    graphql`
      mutation EditTimesheetMutation($input: SaveUserTimesheetInput!) {
        saveUserTimesheet(input: $input) {
          clientMutationId
        }
      }
    `
  );

  function handleOnClose() {
    close();
  }

  function handleCancel() {
    cancel();
  }

  userTimesheet?.lines.forEach((line) => {
    lineValidationShape[`line${line.position}`] = yup
      .number()
      .required('This field is required');

    initialValues[`line${line.position}`] = line.actualHours;
  });

  const lineValidation = yup.object().shape(lineValidationShape);

  return (
    <Box>
      <Drawer open={open} onClose={() => handleOnClose} anchor="right">
        <AppBar
          position="sticky"
          style={{
            backgroundColor: 'white',
            borderBottom: '1px solid lightgrey',
          }}
        >
          <Toolbar>
            <Box
              ml={'-10px'}
              display="flex"
              justifyContent="space-between"
              width="100%"
            >
              <Box>
                <Typography variant="h5">Edit Timesheet</Typography>
                <Typography variant="h6">
                  {`(${userTimesheet?.timesheet.name})`}
                </Typography>
              </Box>
              <IconButton
                style={{
                  backgroundColor: '#F2F8FA',
                  width: '40px',
                  height: '28px',
                  borderRadius: '100px',
                  marginLeft: '10px',
                }}
                onClick={() => {
                  handleCancel();
                }}
              >
                <CloseRoundedIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        <Box className={classes.innerPaper}>
          <Formik<FormikValues>
            initialValues={initialValues}
            validationSchema={lineValidation}
            onSubmit={async (value) => {
              try {
                const response = await mutation({
                  variables: {
                    input: {
                      timesheetId: userTimesheet?.timesheet.id || '',
                      userTimesheet: {
                        timesheetId: userTimesheet?.timesheet.id || '',
                        id: userTimesheet?.id || '',
                        totalActualHours: userTimesheet?.totalActualHours,
                        userId: userTimesheet?.userId || '',
                        lines:
                          userTimesheet?.lines.length === 4
                            ? [
                                {
                                  id: userTimesheet?.lines[0].id || '',
                                  userTimesheetId:
                                    userTimesheet?.lines[0].userTimesheetId,
                                  actualHours: value.line0,
                                  description:
                                    userTimesheet?.lines[0].description,
                                  position: 0,
                                },
                                {
                                  id: userTimesheet?.lines[1].id || '',
                                  userTimesheetId:
                                    userTimesheet?.lines[1].userTimesheetId,
                                  actualHours: value.line1,
                                  description:
                                    userTimesheet?.lines[1].description,
                                  position:
                                    userTimesheet?.lines[1].position || -1,
                                },
                                {
                                  id: userTimesheet?.lines[2].id || '',
                                  userTimesheetId:
                                    userTimesheet?.lines[2].userTimesheetId,
                                  actualHours: value.line2,
                                  description:
                                    userTimesheet?.lines[2].description,
                                  position:
                                    userTimesheet?.lines[2].position || -1,
                                },
                                {
                                  id: userTimesheet?.lines[3].id || '',
                                  userTimesheetId:
                                    userTimesheet?.lines[3].userTimesheetId,
                                  actualHours: value.line3,
                                  description:
                                    userTimesheet?.lines[3].description,
                                  position:
                                    userTimesheet?.lines[3].position || -1,
                                },
                              ]
                            : [
                                {
                                  id: userTimesheet?.lines[0].id || '',
                                  userTimesheetId:
                                    userTimesheet?.lines[0].userTimesheetId,
                                  actualHours: value.line0,
                                  description:
                                    userTimesheet?.lines[0].description,
                                  position: 0,
                                },
                                {
                                  id: userTimesheet?.lines[1].id || '',
                                  userTimesheetId:
                                    userTimesheet?.lines[1].userTimesheetId,
                                  actualHours: value.line1,
                                  description:
                                    userTimesheet?.lines[1].description,
                                  position:
                                    userTimesheet?.lines[1].position || -1,
                                },
                                {
                                  id: userTimesheet?.lines[2].id || '',
                                  userTimesheetId:
                                    userTimesheet?.lines[2].userTimesheetId,
                                  actualHours: value.line2,
                                  description:
                                    userTimesheet?.lines[2].description,
                                  position:
                                    userTimesheet?.lines[2].position || -1,
                                },
                                {
                                  id: userTimesheet?.lines[3].id || '',
                                  userTimesheetId:
                                    userTimesheet?.lines[3].userTimesheetId,
                                  actualHours: value.line3,
                                  description:
                                    userTimesheet?.lines[3].description,
                                  position:
                                    userTimesheet?.lines[3].position || -1,
                                },
                                {
                                  id: userTimesheet?.lines[4].id || '',
                                  userTimesheetId:
                                    userTimesheet?.lines[4].userTimesheetId,
                                  actualHours: value.line4,
                                  description:
                                    userTimesheet?.lines[4].description,
                                  position:
                                    userTimesheet?.lines[4].position || -1,
                                },
                              ],
                      },
                    },
                  },
                });

                if (response) {
                  Toast('success', 'Successfully updated timesheet');
                  handleOnClose();
                }
              } catch (error) {
                Toast('error', error.message);
              }
            }}
          >
            {({submitForm}) => {
              return (
                <Box width={'100%'}>
                  <TableContainer
                    style={{
                      borderRadius: '4px',
                      boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2)',
                    }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Week</TableCell>
                          <TableCell>Actual Hours</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {userTimesheet?.lines.map((line) => {
                          return (
                            <TableRow>
                              <TableCell>{line.description}</TableCell>
                              <TableCell>
                                <FTextField
                                  fullWidth={true}
                                  type={'number'}
                                  field={{
                                    name: `line${line.position}` || '',
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box padding={'10px'}>
                    <Button
                      fullWidth
                      color="primary"
                      variant="contained"
                      disabled={loading}
                      onClick={submitForm}
                    >
                      {loading ? (
                        <CircularProgress size={26} style={{color: 'white'}} />
                      ) : (
                        'Confirm'
                      )}
                    </Button>
                  </Box>
                </Box>
              );
            }}
          </Formik>
        </Box>
      </Drawer>
    </Box>
  );
}
