import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import useLocalStorage from 'react-use/lib/useLocalStorage';
import {useLocation} from '../hooks/useLocation';

export interface LocationDetails {
  lat: number | null;
  lon: number | null;
  permission?: boolean;
}

export interface LocationContextType {
  location: LocationDetails;
  askPermission: () => void;
  error: Error | PositionError | undefined;
}
export const LocationContext = createContext<LocationContextType>({
  location: {
    lat: null,
    lon: null,
  },
  askPermission: () => {
    return true;
  },
  error: undefined,
});

interface Props {
  children: ReactNode;
}
export const LocationProvider = ({children}: Props) => {
  const [permission, setPermission] = useLocalStorage<boolean>(
    'locationPermission'
  );
  const {error, latitude, longitude, loading} = useLocation();
  const [location, setLocation] = useState<LocationDetails>({
    lat: null,
    lon: null,
  });
  //TODO set permission in local storage
  const askPermission = useCallback(async () => {
    /* 
  check if permission is set
   if is set to false
   - do nothing
   if is set to true
   - do nothing
   if undefined 
   - ask permission
  */
    //TODO prompt again if user asks
    if (!window || !window.navigator || !window.navigator.permissions) {
      setPermission(false);
      return false;
    }
    const browserPermission = await window.navigator.permissions.query({
      name: 'geolocation',
    });
    if (browserPermission.state === 'granted' || permission === true) {
      setPermission(true);
      return true;
    } else {
      setPermission(false);
      return false;
    }
  }, [setPermission, permission]);
  useEffect(() => {
    async function checkState() {
      if (!loading) {
        const permission = await askPermission();
        if (
          !error &&
          permission &&
          location.lat === null &&
          location.lon === null
        ) {
          setLocation({
            permission: permission,
            lat: latitude,
            lon: longitude,
          });
        }
      }
    }
    checkState();
  }, [askPermission, location, error, latitude, longitude, loading]);

  const value = useMemo(() => ({location, askPermission, error}), [
    location,
    askPermission,
    error,
  ]);
  return (
    <LocationContext.Provider value={value}>
      {children}
    </LocationContext.Provider>
  );
};
