/* tslint:disable */
/* eslint-disable */

import {ReaderFragment} from 'relay-runtime';
import {FragmentRefs} from 'relay-runtime';
export type LeavesPage_leaves = {
  readonly id: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly mobileNumber: string | null;
  readonly leaves: {
    readonly total: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly startDate: string;
        readonly endDate: string;
        readonly days: number;
        readonly dateCreated: string;
        readonly dateApproved: string | null;
        readonly status: string;
        readonly typeId: string;
        readonly typeDescription: string | null;
      };
    }>;
  };
  readonly ' $refType': 'LeavesPage_leaves';
};
export type LeavesPage_leaves$data = LeavesPage_leaves;
export type LeavesPage_leaves$key = {
  readonly ' $data'?: LeavesPage_leaves$data;
  readonly ' $fragmentRefs': FragmentRefs<'LeavesPage_leaves'>;
};

const node: ReaderFragment = (function () {
  var v0 = {
    alias: null,
    args: null,
    kind: 'ScalarField',
    name: 'id',
    storageKey: null,
  };
  return {
    argumentDefinitions: [
      {
        kind: 'RootArgument',
        name: 'search',
        type: 'String',
      },
      {
        kind: 'RootArgument',
        name: 'first',
        type: 'Int',
      },
      {
        kind: 'RootArgument',
        name: 'after',
        type: 'String',
      },
      {
        kind: 'RootArgument',
        name: 'sort',
        type: 'LeaveSort',
      },
    ],
    kind: 'Fragment',
    metadata: {
      refetch: {
        connection: null,
        fragmentPathInResult: ['node'],
        operation: require('./LeavesPageRefetchQuery.graphql.ts'),
        identifierField: 'id',
      },
    },
    name: 'LeavesPage_leaves',
    selections: [
      v0 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'firstName',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'lastName',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'email',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'mobileNumber',
        storageKey: null,
      },
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'after',
            variableName: 'after',
          },
          {
            kind: 'Variable',
            name: 'first',
            variableName: 'first',
          },
          {
            kind: 'Variable',
            name: 'q',
            variableName: 'search',
          },
          {
            kind: 'Variable',
            name: 'sort',
            variableName: 'sort',
          },
        ],
        concreteType: 'LeaveConnection',
        kind: 'LinkedField',
        name: 'leaves',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'total',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'LeaveConnectionEdge',
            kind: 'LinkedField',
            name: 'edges',
            plural: true,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: 'Leave',
                kind: 'LinkedField',
                name: 'node',
                plural: false,
                selections: [
                  v0 /*: any*/,
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'startDate',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'endDate',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'days',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'dateCreated',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'dateApproved',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'status',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'typeId',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'typeDescription',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ],
    type: 'User',
  };
})();
(node as any).hash = '18e8b4751ded694201c73fd43cc633ca';
export default node;
