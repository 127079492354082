/* tslint:disable */
/* eslint-disable */

import {ConcreteRequest} from 'relay-runtime';
import {FragmentRefs} from 'relay-runtime';
export type LeaveSortField =
  | 'APPROVED_DATE'
  | 'CREATED_DATE'
  | 'DAYS'
  | 'END_DATE'
  | 'START_DATE'
  | 'STATUS'
  | 'TYPE'
  | '%future added value';
export type SortDirection = 'ASCENDING' | 'DESCENDING' | '%future added value';
export type LeaveSort = {
  field: LeaveSortField;
  direction?: SortDirection | null;
};
export type HeaderQueryVariables = {
  search?: string | null;
  first?: number | null;
  after?: string | null;
  sort?: LeaveSort | null;
};
export type HeaderQueryResponse = {
  readonly viewer: {
    readonly user: {
      readonly ' $fragmentRefs': FragmentRefs<
        | 'Header_userInformation'
        | 'ProfilePage_user'
        | 'TaskPage_tasks'
        | 'LeavesPage_leaves'
      >;
    } | null;
  };
};
export type HeaderQuery = {
  readonly response: HeaderQueryResponse;
  readonly variables: HeaderQueryVariables;
};

/*
query HeaderQuery(
  $search: String
  $first: Int
  $after: String
  $sort: LeaveSort
) {
  viewer {
    user {
      ...Header_userInformation
      ...ProfilePage_user
      ...TaskPage_tasks
      ...LeavesPage_leaves
      id
    }
  }
}

fragment Header_userInformation on User {
  firstName
  profileImageUri
  tasks(q: $search, first: $first, after: $after) {
    total
  }
}

fragment LeavesPage_leaves on User {
  id
  firstName
  lastName
  email
  mobileNumber
  leaves(q: $search, first: $first, after: $after, sort: $sort) {
    total
    edges {
      node {
        id
        startDate
        endDate
        days
        dateCreated
        dateApproved
        status
        typeId
        typeDescription
      }
    }
  }
}

fragment ProfilePage_user on User {
  displayName
  email
  firstName
  id
  lastName
  mobileNumber
  profileImageUri
  username
  dateOfBirth
  incomeTaxNumber
  personalReferenceType
  personalReferenceValue
  addresses {
    id
    building
    street
    city
    postcode
    province
    country
    preferred
    prettyFormat
  }
}

fragment TaskPage_tasks on User {
  id
  firstName
  lastName
  email
  mobileNumber
  tasks(q: $search, first: $first, after: $after) {
    total
    edges {
      node {
        id
        description
        dateCreated
        taskId
        taskType
        time
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'search',
        type: 'String',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'first',
        type: 'Int',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'after',
        type: 'String',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'sort',
        type: 'LeaveSort',
      },
    ],
    v1 = {
      kind: 'Variable',
      name: 'after',
      variableName: 'after',
    },
    v2 = {
      kind: 'Variable',
      name: 'first',
      variableName: 'first',
    },
    v3 = {
      kind: 'Variable',
      name: 'q',
      variableName: 'search',
    },
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'total',
      storageKey: null,
    },
    v5 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v6 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'dateCreated',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'HeaderQuery',
      selections: [
        {
          alias: null,
          args: null,
          concreteType: 'Viewer',
          kind: 'LinkedField',
          name: 'viewer',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'User',
              kind: 'LinkedField',
              name: 'user',
              plural: false,
              selections: [
                {
                  args: null,
                  kind: 'FragmentSpread',
                  name: 'Header_userInformation',
                },
                {
                  args: null,
                  kind: 'FragmentSpread',
                  name: 'ProfilePage_user',
                },
                {
                  args: null,
                  kind: 'FragmentSpread',
                  name: 'TaskPage_tasks',
                },
                {
                  args: null,
                  kind: 'FragmentSpread',
                  name: 'LeavesPage_leaves',
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Query',
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'HeaderQuery',
      selections: [
        {
          alias: null,
          args: null,
          concreteType: 'Viewer',
          kind: 'LinkedField',
          name: 'viewer',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'User',
              kind: 'LinkedField',
              name: 'user',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'firstName',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'profileImageUri',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: [v1 /*: any*/, v2 /*: any*/, v3 /*: any*/],
                  concreteType: 'TaskConnection',
                  kind: 'LinkedField',
                  name: 'tasks',
                  plural: false,
                  selections: [
                    v4 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      concreteType: 'TaskConnectionEdge',
                      kind: 'LinkedField',
                      name: 'edges',
                      plural: true,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          concreteType: 'Task',
                          kind: 'LinkedField',
                          name: 'node',
                          plural: false,
                          selections: [
                            v5 /*: any*/,
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'description',
                              storageKey: null,
                            },
                            v6 /*: any*/,
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'taskId',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'taskType',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'time',
                              storageKey: null,
                            },
                          ],
                          storageKey: null,
                        },
                      ],
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'displayName',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'email',
                  storageKey: null,
                },
                v5 /*: any*/,
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'lastName',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'mobileNumber',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'username',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'dateOfBirth',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'incomeTaxNumber',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'personalReferenceType',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'personalReferenceValue',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  concreteType: 'Address',
                  kind: 'LinkedField',
                  name: 'addresses',
                  plural: true,
                  selections: [
                    v5 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'building',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'street',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'city',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'postcode',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'province',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'country',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'preferred',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'prettyFormat',
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
                {
                  alias: null,
                  args: [
                    v1 /*: any*/,
                    v2 /*: any*/,
                    v3 /*: any*/,
                    {
                      kind: 'Variable',
                      name: 'sort',
                      variableName: 'sort',
                    },
                  ],
                  concreteType: 'LeaveConnection',
                  kind: 'LinkedField',
                  name: 'leaves',
                  plural: false,
                  selections: [
                    v4 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      concreteType: 'LeaveConnectionEdge',
                      kind: 'LinkedField',
                      name: 'edges',
                      plural: true,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          concreteType: 'Leave',
                          kind: 'LinkedField',
                          name: 'node',
                          plural: false,
                          selections: [
                            v5 /*: any*/,
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'startDate',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'endDate',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'days',
                              storageKey: null,
                            },
                            v6 /*: any*/,
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'dateApproved',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'status',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'typeId',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'typeDescription',
                              storageKey: null,
                            },
                          ],
                          storageKey: null,
                        },
                      ],
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      id: null,
      metadata: {},
      name: 'HeaderQuery',
      operationKind: 'query',
      text:
        'query HeaderQuery(\n  $search: String\n  $first: Int\n  $after: String\n  $sort: LeaveSort\n) {\n  viewer {\n    user {\n      ...Header_userInformation\n      ...ProfilePage_user\n      ...TaskPage_tasks\n      ...LeavesPage_leaves\n      id\n    }\n  }\n}\n\nfragment Header_userInformation on User {\n  firstName\n  profileImageUri\n  tasks(q: $search, first: $first, after: $after) {\n    total\n  }\n}\n\nfragment LeavesPage_leaves on User {\n  id\n  firstName\n  lastName\n  email\n  mobileNumber\n  leaves(q: $search, first: $first, after: $after, sort: $sort) {\n    total\n    edges {\n      node {\n        id\n        startDate\n        endDate\n        days\n        dateCreated\n        dateApproved\n        status\n        typeId\n        typeDescription\n      }\n    }\n  }\n}\n\nfragment ProfilePage_user on User {\n  displayName\n  email\n  firstName\n  id\n  lastName\n  mobileNumber\n  profileImageUri\n  username\n  dateOfBirth\n  incomeTaxNumber\n  personalReferenceType\n  personalReferenceValue\n  addresses {\n    id\n    building\n    street\n    city\n    postcode\n    province\n    country\n    preferred\n    prettyFormat\n  }\n}\n\nfragment TaskPage_tasks on User {\n  id\n  firstName\n  lastName\n  email\n  mobileNumber\n  tasks(q: $search, first: $first, after: $after) {\n    total\n    edges {\n      node {\n        id\n        description\n        dateCreated\n        taskId\n        taskType\n        time\n      }\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = '3370b1450aa20dc7eeb8de37626406bb';
export default node;
