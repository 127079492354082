import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Link,
  Typography,
} from '@material-ui/core';
import {Form, Formik} from 'formik';
import FormItem from '../../atoms/FormItem';
import {FTextFieldPassword} from '../../modules/FMaterial/FTextfield/FTextFieldPassword';
import {useNavigate, useLocation} from 'react-router';
import * as yup from 'yup';
import graphql from 'babel-plugin-relay/macro';
import {useMutation} from 'relay-hooks';
import {Toast} from '../../modules/Toast';
import {useAuthContext} from '../../context/AuthContext';
import {ResetPasswordPageMutation} from '../../__generated__/ResetPasswordPageMutation.graphql';

const validationSchema = yup.object().shape({
  password: yup.string().required('Password is required.'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), undefined], "Passwords don't match")
    .required('Confirm Password is required'),
});

export function ResetPasswordPage() {
  const navigate = useNavigate();
  const {auth} = useAuthContext();

  const {search} = useLocation();
  const queryParams = new URLSearchParams(search);

  const [mutation, {loading}] = useMutation<ResetPasswordPageMutation>(graphql`
    mutation ResetPasswordPageMutation($input: ResetPasswordInput!) {
      resetPassword(input: $input) {
        processInstanceId
      }
    }
  `);

  React.useEffect(() => {
    if (auth.authenticated) {
      navigate('/');
    }
  }, [navigate, auth.authenticated]);

  if (auth.authenticated) {
    return <></>;
  }
  return (
    <Box display="flex" justifyContent="center" pl={5}>
      <Formik
        initialValues={{
          password: '',
          confirmPassword: '',
        }}
        validationSchema={validationSchema}
        onSubmit={async ({password, confirmPassword}) => {
          try {
            const response = await mutation({
              variables: {
                input: {
                  plainTextPassword: password,
                  confirmPlainTextPassword: confirmPassword,
                  activationSerialNumber: queryParams.get('asn') || '',
                },
              },
            });
            if (response) {
              Toast('success', 'Password successfully reset');
              navigate('/login');
            } else {
              Toast('error', 'Something went wrong');
            }
          } catch (error) {
            Toast('error', error);
          }
        }}
      >
        {({submitForm}) => {
          return (
            <Box width="100%">
              <Form
                style={{display: 'flex', justifyContent: 'center'}}
                onKeyDown={(event) => {
                  if (event.keyCode === 13) {
                    event.preventDefault();
                    submitForm();
                  }
                }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  width="500px"
                  pb={10}
                >
                  <Box pb={3} textAlign="center" maxWidth="500px">
                    <Typography variant="h5">Change Password</Typography>
                  </Box>

                  <FormItem>
                    <FTextFieldPassword
                      fullWidth
                      field={{
                        name: 'password',
                      }}
                      type="password"
                      label="Password"
                    />
                  </FormItem>

                  <FormItem>
                    <FTextFieldPassword
                      fullWidth
                      field={{
                        name: 'confirmPassword',
                      }}
                      type="password"
                      label="Confirm password"
                    />
                  </FormItem>
                  <FormItem>
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      fullWidth
                      onClick={() => {
                        submitForm();
                      }}
                    >
                      {loading ? (
                        <CircularProgress size={26} style={{color: 'white'}} />
                      ) : (
                        'Reset'
                      )}
                    </Button>
                  </FormItem>
                  <Box display="flex" justifyContent="center" pb={3}>
                    <Link
                      component={'button'}
                      color="secondary"
                      onClick={() => {
                        navigate('/login');
                      }}
                    >
                      Sign In
                    </Link>
                  </Box>
                </Box>
              </Form>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
}
