import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {
  FormikStep,
  FormikStepper,
} from '../../modules/FormikStepper/FormikStepper';
import graphql from 'babel-plugin-relay/macro';
import {useMutation, useQuery} from 'relay-hooks/lib';
import {CaptureTimesheetPageQuery} from '../../__generated__/CaptureTimesheetPageQuery.graphql';
import {FDropdown} from '../../modules/FMaterial/FDropdown/FDropdown';
import * as yup from 'yup';
import {CaptureTimesheetPageMutation} from '../../__generated__/CaptureTimesheetPageMutation.graphql';
import {FTextField} from '../../modules/FMaterial/FTextfield/FTextField';
import {useNavigate} from 'react-router';
import {useAuthContext} from '../../context/AuthContext';
import {Toast} from '../../modules/Toast';

const validationSchema = yup.object().shape({
  billingPeriod: yup.string().required('This field is required'),
  line0: yup.number().required('Required'),
});

const billingPeriodValidation = yup.object().shape({
  billingPeriod: yup.string().required('This field is required'),
});

const lineValidationShape: any = {};

const lineValidation = yup.object();

interface UserTimesheetLineInterface {
  id: string | null;
  userTimesheetId: string | null;
  position: number;
  description: string | null;
  actualHours: number | null;
}

interface TimesheetInterface {
  id: string;
  billableHours: number;
  description: string;
  name: string;
  startDate: string;
  endDate: string;
}
interface UserTimesheetInterface {
  id: string | null;
  totalActualHours: number | null;
  userId: string | null;
  lines: readonly UserTimesheetLineInterface[];
  timesheet: TimesheetInterface;
}

const query = graphql`
  query CaptureTimesheetPageQuery {
    viewer {
      lastXMonthsTimesheetPeriods(number: 3) {
        id
        startDate
        endDate
        billableHours
        name
        description
      }
    }
  }
`;

export function CaptureTimesheetPage() {
  const navigate = useNavigate();

  const {auth} = useAuthContext();

  React.useEffect(() => {
    if (!auth.authenticated) {
      navigate('/login');
    }
  }, [navigate, auth.authenticated]);

  const {props} = useQuery<CaptureTimesheetPageQuery>(query);

  const [
    userTimesheet,
    setUserTimesheet,
  ] = React.useState<UserTimesheetInterface | null>(null);

  const [mutation] = useMutation<CaptureTimesheetPageMutation>(
    graphql`
      mutation CaptureTimesheetPageMutation($input: SaveUserTimesheetInput!) {
        saveUserTimesheet(input: $input) {
          userTimesheet {
            id
            totalActualHours
            userId
            lines {
              id
              userTimesheetId
              position
              description
              actualHours
            }
            timesheet {
              id
              billableHours
              description
              name
              startDate
              endDate
            }
          }
        }
      }
    `
  );

  if (!props) {
    return <></>;
  }

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      flexDirection="column"
      pl={5}
    >
      <Card style={{width: '100%', boxShadow: 'none'}}>
        <CardContent>
          <FormikStepper
            enableReinitialize={true}
            validationSchema={validationSchema}
            initialValues={{
              billingPeriod: '',
            }}
            onSubmit={async (value) => {
              try {
                const response = await mutation({
                  variables: {
                    input: {
                      timesheetId: value.billingPeriod,
                      userTimesheet: {
                        timesheetId: userTimesheet?.timesheet.id || '',
                        id: userTimesheet?.id,
                        totalActualHours: userTimesheet?.totalActualHours,
                        userId: userTimesheet?.userId || '',
                        lines:
                          userTimesheet?.lines.length === 4
                            ? [
                                {
                                  id: userTimesheet?.lines[0].id || '',
                                  userTimesheetId:
                                    userTimesheet?.lines[0].userTimesheetId,
                                  actualHours: value.line0,
                                  description:
                                    userTimesheet?.lines[0].description,
                                  position: 0,
                                },
                                {
                                  id: userTimesheet?.lines[1].id || '',
                                  userTimesheetId:
                                    userTimesheet?.lines[1].userTimesheetId,
                                  actualHours: value.line1,
                                  description:
                                    userTimesheet?.lines[1].description,
                                  position:
                                    userTimesheet?.lines[1].position || -1,
                                },
                                {
                                  id: userTimesheet?.lines[2].id || '',
                                  userTimesheetId:
                                    userTimesheet?.lines[2].userTimesheetId,
                                  actualHours: value.line2,
                                  description:
                                    userTimesheet?.lines[2].description,
                                  position:
                                    userTimesheet?.lines[2].position || -1,
                                },
                                {
                                  id: userTimesheet?.lines[3].id || '',
                                  userTimesheetId:
                                    userTimesheet?.lines[3].userTimesheetId,
                                  actualHours: value.line3,
                                  description:
                                    userTimesheet?.lines[3].description,
                                  position:
                                    userTimesheet?.lines[3].position || -1,
                                },
                              ]
                            : [
                                {
                                  id: userTimesheet?.lines[0].id || '',
                                  userTimesheetId:
                                    userTimesheet?.lines[0].userTimesheetId,
                                  actualHours: value.line0,
                                  description:
                                    userTimesheet?.lines[0].description,
                                  position: 0,
                                },
                                {
                                  id: userTimesheet?.lines[1].id || '',
                                  userTimesheetId:
                                    userTimesheet?.lines[1].userTimesheetId,
                                  actualHours: value.line1,
                                  description:
                                    userTimesheet?.lines[1].description,
                                  position:
                                    userTimesheet?.lines[1].position || -1,
                                },
                                {
                                  id: userTimesheet?.lines[2].id || '',
                                  userTimesheetId:
                                    userTimesheet?.lines[2].userTimesheetId,
                                  actualHours: value.line2,
                                  description:
                                    userTimesheet?.lines[2].description,
                                  position:
                                    userTimesheet?.lines[2].position || -1,
                                },
                                {
                                  id: userTimesheet?.lines[3].id || '',
                                  userTimesheetId:
                                    userTimesheet?.lines[3].userTimesheetId,
                                  actualHours: value.line3,
                                  description:
                                    userTimesheet?.lines[3].description,
                                  position:
                                    userTimesheet?.lines[3].position || -1,
                                },
                                {
                                  id: userTimesheet?.lines[4].id || '',
                                  userTimesheetId:
                                    userTimesheet?.lines[4].userTimesheetId,
                                  actualHours: value.line4,
                                  description:
                                    userTimesheet?.lines[4].description,
                                  position:
                                    userTimesheet?.lines[4].position || -1,
                                },
                              ],
                      },
                    },
                  },
                });

                if (response) {
                  navigate('/timesheets');
                }
              } catch (error) {
                Toast('error', error.message);
              }
            }}
          >
            <FormikStep
              label="Select Billing Period"
              toastMessage="Please choose the billing period"
              validationSchema={billingPeriodValidation}
              handleFunction={async (value) => {
                const response = await mutation({
                  variables: {
                    input: {
                      timesheetId: value.billingPeriod,
                    },
                  },
                });

                if (response) {
                  response.saveUserTimesheet.userTimesheet.lines.forEach(
                    (line) => {
                      lineValidationShape[
                        `line${line.position}`
                      ] = yup.number().required('This field is required');
                    }
                  );
                  // lineValidation.shape(lineValidationShape);
                  // lineValidation.fields = lineValidationShape;
                  setUserTimesheet(response.saveUserTimesheet.userTimesheet);
                }
              }}
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box width="600px" mr={5}>
                  <Box
                    px={2}
                    pb={2}
                    style={{boxSizing: 'border-box'}}
                    width="100%"
                  >
                    <FDropdown
                      field={{name: 'billingPeriod'}}
                      fullWidth
                      label={'Select the billing period'}
                      data={props?.viewer.lastXMonthsTimesheetPeriods.map(
                        (period) => {
                          return {
                            id: period.id,
                            description: period.description,
                          };
                        }
                      )}
                    />
                  </Box>
                </Box>
              </Box>
            </FormikStep>

            <FormikStep
              label="Capture Actual Hours"
              validationSchema={lineValidation}
              handleFunction={() => {}}
              toastMessage="Please capture the actual hours you worked"
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box width="800px" mr={5}>
                  <TableContainer
                    style={{
                      borderRadius: '4px',
                      boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2)',
                    }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Week</TableCell>
                          <TableCell>Actual Hours</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {userTimesheet?.lines.map((line) => {
                          return (
                            <TableRow>
                              <TableCell>{line.description}</TableCell>
                              <TableCell>
                                <FTextField
                                  fullWidth={true}
                                  type={'number'}
                                  field={{name: `line${line.position}` || ''}}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </FormikStep>
          </FormikStepper>
        </CardContent>
      </Card>
    </Box>
  );
}
