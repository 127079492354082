import * as React from 'react';
import {Environment} from 'relay-runtime';
import createRelayEnv from './CreateRelayEnv';
import {RelayEnvironmentProvider} from 'relay-hooks';
import {useAuthContext} from './AuthContext';
import isEqual from 'react-fast-compare';

interface Props {
  children: React.ReactNode;
}
export default function RelayContextProvider({children}: Props) {
  const {auth, handleLogout} = useAuthContext();
  const authRef = React.useRef(auth);
  const createEnvironment: () => Environment = React.useCallback(() => {
    return createRelayEnv(
      () => {
        handleLogout();
      },
      () => {
        if (auth.authenticated) {
          return auth.token;
        } else {
          return '';
        }

        //return '';
      }
    );
  }, [auth, handleLogout]);
  const [environment, setEnvironment] = React.useState(createEnvironment());

  React.useEffect(() => {
    if (!isEqual(authRef.current, auth) && environment) {
      setEnvironment(createEnvironment());
    }

    authRef.current = auth;
  }, [auth, createEnvironment, environment]);
  const value = React.useMemo(() => environment, [environment]);
  return (
    <RelayEnvironmentProvider environment={value}>
      {children}
    </RelayEnvironmentProvider>
  );
}
