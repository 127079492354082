/* tslint:disable */
/* eslint-disable */

import {ConcreteRequest} from 'relay-runtime';
import {FragmentRefs} from 'relay-runtime';
export type UpdateUserInput = {
  clientMutationId?: string | null;
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  mobileNumber?: string | null;
  profileImageUri: string;
  personalReferenceType: string;
  personalReferenceValue: string;
  dateOfBirth: string;
  incomeTaxNumber: string;
};
export type ProfilePageMutationVariables = {
  input: UpdateUserInput;
  search?: string | null;
  first?: number | null;
  after?: string | null;
};
export type ProfilePageMutationResponse = {
  readonly updateUser: {
    readonly user: {
      readonly ' $fragmentRefs': FragmentRefs<
        'Header_userInformation' | 'ProfilePage_user'
      >;
    };
    readonly processInstanceId: string;
    readonly clientMutationId: string | null;
  };
};
export type ProfilePageMutation = {
  readonly response: ProfilePageMutationResponse;
  readonly variables: ProfilePageMutationVariables;
};

/*
mutation ProfilePageMutation(
  $input: UpdateUserInput!
  $search: String
  $first: Int
  $after: String
) {
  updateUser(input: $input) {
    user {
      ...Header_userInformation
      ...ProfilePage_user
      id
    }
    processInstanceId
    clientMutationId
  }
}

fragment Header_userInformation on User {
  firstName
  profileImageUri
  tasks(q: $search, first: $first, after: $after) {
    total
  }
}

fragment ProfilePage_user on User {
  displayName
  email
  firstName
  id
  lastName
  mobileNumber
  profileImageUri
  username
  dateOfBirth
  incomeTaxNumber
  personalReferenceType
  personalReferenceValue
  addresses {
    id
    building
    street
    city
    postcode
    province
    country
    preferred
    prettyFormat
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'input',
        type: 'UpdateUserInput!',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'search',
        type: 'String',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'first',
        type: 'Int',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'after',
        type: 'String',
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'input',
        variableName: 'input',
      },
    ],
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'processInstanceId',
      storageKey: null,
    },
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'clientMutationId',
      storageKey: null,
    },
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'ProfilePageMutation',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'UpdateUserPayload',
          kind: 'LinkedField',
          name: 'updateUser',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'User',
              kind: 'LinkedField',
              name: 'user',
              plural: false,
              selections: [
                {
                  args: null,
                  kind: 'FragmentSpread',
                  name: 'Header_userInformation',
                },
                {
                  args: null,
                  kind: 'FragmentSpread',
                  name: 'ProfilePage_user',
                },
              ],
              storageKey: null,
            },
            v2 /*: any*/,
            v3 /*: any*/,
          ],
          storageKey: null,
        },
      ],
      type: 'Mutation',
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'ProfilePageMutation',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'UpdateUserPayload',
          kind: 'LinkedField',
          name: 'updateUser',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'User',
              kind: 'LinkedField',
              name: 'user',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'firstName',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'profileImageUri',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: [
                    {
                      kind: 'Variable',
                      name: 'after',
                      variableName: 'after',
                    },
                    {
                      kind: 'Variable',
                      name: 'first',
                      variableName: 'first',
                    },
                    {
                      kind: 'Variable',
                      name: 'q',
                      variableName: 'search',
                    },
                  ],
                  concreteType: 'TaskConnection',
                  kind: 'LinkedField',
                  name: 'tasks',
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'total',
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'displayName',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'email',
                  storageKey: null,
                },
                v4 /*: any*/,
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'lastName',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'mobileNumber',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'username',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'dateOfBirth',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'incomeTaxNumber',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'personalReferenceType',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'personalReferenceValue',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  concreteType: 'Address',
                  kind: 'LinkedField',
                  name: 'addresses',
                  plural: true,
                  selections: [
                    v4 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'building',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'street',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'city',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'postcode',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'province',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'country',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'preferred',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'prettyFormat',
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
            v2 /*: any*/,
            v3 /*: any*/,
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      id: null,
      metadata: {},
      name: 'ProfilePageMutation',
      operationKind: 'mutation',
      text:
        'mutation ProfilePageMutation(\n  $input: UpdateUserInput!\n  $search: String\n  $first: Int\n  $after: String\n) {\n  updateUser(input: $input) {\n    user {\n      ...Header_userInformation\n      ...ProfilePage_user\n      id\n    }\n    processInstanceId\n    clientMutationId\n  }\n}\n\nfragment Header_userInformation on User {\n  firstName\n  profileImageUri\n  tasks(q: $search, first: $first, after: $after) {\n    total\n  }\n}\n\nfragment ProfilePage_user on User {\n  displayName\n  email\n  firstName\n  id\n  lastName\n  mobileNumber\n  profileImageUri\n  username\n  dateOfBirth\n  incomeTaxNumber\n  personalReferenceType\n  personalReferenceValue\n  addresses {\n    id\n    building\n    street\n    city\n    postcode\n    province\n    country\n    preferred\n    prettyFormat\n  }\n}\n',
    },
  };
})();
(node as any).hash = '4de19ec80d43fa2210ce62959103cda5';
export default node;
