import React, {useMemo} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {CircularProgress, Dialog, Typography} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import graphql from 'babel-plugin-relay/macro';
import {useMutation, useQuery} from 'relay-hooks/lib';
import {ActionLeaveQuery} from '../../__generated__/ActionLeaveQuery.graphql';
import {ActionLeaveMutation} from '../../__generated__/ActionLeaveMutation.graphql';
import {Toast} from '../Toast';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display: 'flex',
      justifyContent: 'center',
      borderRadius: '15px',
    },
    innerPaper: {
      maxWidth: '500px',
      minWidth: '25vw',
      margin: '0 auto',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100vw',
      },
    },
    buttonSection: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      padding: theme.spacing(2),
      boxSizing: 'border-box',
    },
    buttonPadding: {
      padding: theme.spacing(1),
      maxWidth: '250px',
      flexBasis: '100px',
      flexGrow: 1,
    },
    deleteButton: {
      marginRight: '20%',
      // backgroundColor: "red"
    },
    boldText: {
      fontWeight: 'bold',
    },
  })
);

const query = graphql`
  query ActionLeaveQuery($taskId: Int) {
    viewer {
      viewLeaveRequestDetails(taskId: $taskId) {
        days
        startDate
        endDate
        leaveType
        requestingUser
        taskId
      }
    }
  }
`;

interface LeaveRequestDetailsProps {
  taskId: string;
  requestingUser: string;
  leaveType: string;
  startDate: string;
  endDate: string;
  days: number;
}

interface ActionLeaveProps {
  handlePromptResponse?: () => void;
  onClose: () => void;
  taskId: number;
}

export interface DialogContextType {
  notify(data: ActionLeaveProps): void;
}

export const ViewLeaveRequest = React.createContext<DialogContextType>({
  notify: () => null,
});

interface Props {
  children: React.ReactNode;
}

export function ActionLeave({children}: Props) {
  /*const [mutation, {loading}] = useMutation<EditFeatureMutation>(
        graphql`
            mutation EditFeatureMutation($input: SaveFeatureInput!) {
                saveFeature(input: $input) {
                    clientMutationId
                    feature {
                        id
                    }
                }
            }
        `
    );*/

  const [actionLeaveMutation, {loading}] = useMutation<
    ActionLeaveMutation
  >(graphql`
    mutation ActionLeaveMutation(
      $input: ActionLeaveInput!
      $search: String
      $first: Int
      $after: String
      $sort: LeaveSort
    ) {
      actionLeave(input: $input) {
        clientMutationId
        processInstanceId
        user {
          ...LeavesPage_leaves
          ...TaskPage_tasks
          ...Header_userInformation
        }
      }
    }
  `);

  const [open, setOpen] = React.useState(false);
  const [dialogState, setDialogState] = React.useState<ActionLeaveProps>();

  const {props} = useQuery<ActionLeaveQuery>(query, {
    taskId: dialogState?.taskId || null,
  });

  const classes = useStyles();

  const dismiss = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const notify = React.useCallback((snack: ActionLeaveProps) => {
    setDialogState(snack);
    setOpen(true);
  }, []);

  const value = useMemo(() => ({dismiss, notify}), [dismiss, notify]);
  const handleClose = () => {
    setOpen(false);
    dialogState?.onClose();
  };

  return (
    <ViewLeaveRequest.Provider value={value}>
      <Dialog
        PaperProps={{
          classes: {
            root: classes.paper,
          },
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Box className={classes.innerPaper}>
          <DialogTitle id="alert-dialog-slide-title">
            <Box display="flex" alignItems="center">
              View Leave Request
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Box
                display="flex"
                flexDirection={'row'}
                justifyContent={'space-between'}
              >
                <Typography variant="subtitle1">Requesting User:</Typography>

                <Typography className={classes.boldText} variant="subtitle1">
                  {props?.viewer.viewLeaveRequestDetails
                    ? props?.viewer.viewLeaveRequestDetails.requestingUser
                    : ''}
                </Typography>
              </Box>

              <Box
                display="flex"
                flexDirection={'row'}
                justifyContent={'space-between'}
              >
                <Typography variant="subtitle1">Leave Type:</Typography>

                <Typography className={classes.boldText} variant="subtitle1">
                  {props?.viewer.viewLeaveRequestDetails
                    ? props?.viewer.viewLeaveRequestDetails.leaveType
                    : ''}
                </Typography>
              </Box>

              <Box
                display="flex"
                flexDirection={'row'}
                justifyContent={'space-between'}
              >
                <Typography variant="subtitle1">Start Date:</Typography>

                <Typography className={classes.boldText} variant="subtitle1">
                  {props?.viewer.viewLeaveRequestDetails
                    ? props?.viewer.viewLeaveRequestDetails.startDate
                    : ''}
                </Typography>
              </Box>

              <Box
                display="flex"
                flexDirection={'row'}
                justifyContent={'space-between'}
              >
                <Typography variant="subtitle1">End Date:</Typography>

                <Typography className={classes.boldText} variant="subtitle1">
                  {props?.viewer.viewLeaveRequestDetails
                    ? props?.viewer.viewLeaveRequestDetails.endDate
                    : ''}
                </Typography>
              </Box>

              <Box
                display="flex"
                flexDirection={'row'}
                justifyContent={'space-between'}
              >
                <Typography variant="subtitle1">Number of Days:</Typography>

                <Typography className={classes.boldText} variant="subtitle1">
                  {props?.viewer.viewLeaveRequestDetails
                    ? props?.viewer.viewLeaveRequestDetails.days
                    : ''}
                </Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.buttonSection}>
            <Box className={classes.deleteButton}>
              <Button
                disabled={loading}
                fullWidth
                color="primary"
                variant="contained"
                onClick={async () => {
                  try {
                    const response = await actionLeaveMutation({
                      variables: {
                        input: {
                          action: 'DECLINE',
                          taskId: dialogState?.taskId || 0,
                        },
                      },
                    });

                    if (response) {
                      Toast('success', 'Leave Declined');
                      handleClose();
                    }
                  } catch (e) {
                    Toast('error', e.message);
                  }
                }}
              >
                {loading ? (
                  <CircularProgress size={26} style={{color: 'white'}} />
                ) : (
                  'Decline'
                )}
              </Button>
            </Box>

            <Box className={classes.buttonPadding}>
              <Button
                disabled={loading}
                fullWidth
                color="secondary"
                variant="contained"
                onClick={async () => {
                  try {
                    const response = await actionLeaveMutation({
                      variables: {
                        input: {
                          action: 'APPROVE',
                          taskId: dialogState?.taskId || 0,
                        },
                      },
                    });

                    if (response) {
                      Toast('success', 'Leave Approved successfully');
                      handleClose();
                    }
                  } catch (e) {
                    Toast('error', e.message);
                  }
                }}
              >
                {loading ? (
                  <CircularProgress size={26} style={{color: 'white'}} />
                ) : (
                  'Approve'
                )}
              </Button>
            </Box>
            <Box className={classes.buttonPadding}>
              <Button
                disabled={loading}
                fullWidth
                color="primary"
                variant="text"
                onClick={() => {
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Dialog>
      {children}
    </ViewLeaveRequest.Provider>
  );
}
