import React from 'react';
import {
  Box,
  Button,
  createStyles,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import graphql from 'babel-plugin-relay/macro';
import {useQuery, useRefetchable} from 'relay-hooks/lib';
import {makeStyles} from '@material-ui/core/styles';
import {Form, Formik} from 'formik';
import {FTextField} from '../../modules/FMaterial/FTextfield/FTextField';
import {useNavigate} from 'react-router';
import {useAuthContext} from '../../context/AuthContext';
import {LeavesPageQuery} from '../../__generated__/LeavesPageQuery.graphql';
import {LeavesPage_leaves$key} from '../../__generated__/LeavesPage_leaves.graphql';

const useStyles = makeStyles(() =>
  createStyles({
    columnHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '0px',
      width: '100%',
      flexDirection: 'row',
    },
    cell: {
      '&:hover': {
        backgroundColor: '#faf9f7',
        cursor: 'pointer',
      },
    },
  })
);

const query = graphql`
  query LeavesPageQuery(
    $search: String
    $first: Int
    $after: String
    $sort: LeaveSort
  ) {
    viewer {
      user {
        ...LeavesPage_leaves
      }
    }
  }
`;
const fragment = graphql`
  fragment LeavesPage_leaves on User
    @refetchable(queryName: "LeavesPageRefetchQuery") {
    id
    firstName
    lastName
    email
    mobileNumber
    leaves(q: $search, first: $first, after: $after, sort: $sort) {
      total
      edges {
        node {
          id
          startDate
          endDate
          days
          dateCreated
          dateApproved
          status
          typeId
          typeDescription
        }
      }
    }
  }
`;

interface Props {
  node: LeavesPage_leaves$key | null;
}

interface SearchProps {
  search: string;
}

export function LeavesPage({node}: Props) {
  const classes = useStyles();
  const navigate = useNavigate();

  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [searchValue, setSearchValue] = React.useState();

  const [fragmentNode, refetch] = useRefetchable(fragment, node);

  const userId = fragmentNode?.id;

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [sort, setSort] = React.useState({
    field: 'TYPE',
    direction: 'ASCENDING',
  });

  React.useEffect(() => {
    refetch({
      id: userId || '',
      search: searchValue,
      sort: sort,
      first: rowsPerPage,
      after: searchValue
        ? btoa(`arrayconnection:-1`)
        : btoa(`arrayconnection:${rowsPerPage * page - 1}`),
    });
  }, [refetch, searchValue, rowsPerPage, page, sort, userId]);

  return (
    <Box>
      <Box
        pl={6}
        pb={3}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Typography variant="h4">Leaves</Typography>

        <Button
          color={'primary'}
          onClick={() => {
            navigate('/leaveapplication');
          }}
        >
          Apply for leave
        </Button>
      </Box>

      <Box>
        <Formik<SearchProps>
          initialValues={{
            search: '',
          }}
          onSubmit={({search}) => {
            setSearchValue(search);
            setPage(0);
          }}
        >
          {({submitForm}) => {
            return (
              <Box display="flex" justifyContent="center" pl={5} pb={5}>
                <Form
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                  onKeyDown={(event) => {
                    if (event.keyCode === 13) {
                      event.preventDefault();
                      submitForm();
                    }
                  }}
                >
                  <TableContainer
                    style={{
                      borderRadius: '4px',
                      boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2)',
                    }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Typography variant={'h6'}>Leaves</Typography>
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>
                            <FTextField
                              field={{name: 'search'}}
                              label="Search"
                            />
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            className={classes.cell}
                            onClick={() => {
                              const direction =
                                sort.direction === 'ASCENDING'
                                  ? 'DESCENDING'
                                  : 'ASCENDING';

                              setSort({
                                field: 'TYPE',
                                direction: direction,
                              });
                            }}
                          >
                            <Box className={classes.columnHeader}>
                              <Typography>Type</Typography>
                              <TableSortLabel
                                active={sort.field === 'TYPE'}
                                direction={
                                  sort.direction === 'ASCENDING'
                                    ? 'asc'
                                    : 'desc'
                                }
                              ></TableSortLabel>
                            </Box>
                          </TableCell>
                          <TableCell
                            className={classes.cell}
                            onClick={() => {
                              const direction =
                                sort.direction === 'ASCENDING'
                                  ? 'DESCENDING'
                                  : 'ASCENDING';

                              setSort({
                                field: 'START_DATE',
                                direction: direction,
                              });
                            }}
                          >
                            <Box className={classes.columnHeader}>
                              <Typography>Start Date</Typography>
                              <TableSortLabel
                                active={sort.field === 'START_DATE'}
                                direction={
                                  sort.direction === 'ASCENDING'
                                    ? 'asc'
                                    : 'desc'
                                }
                              ></TableSortLabel>
                            </Box>
                          </TableCell>
                          <TableCell
                            className={classes.cell}
                            onClick={() => {
                              const direction =
                                sort.direction === 'ASCENDING'
                                  ? 'DESCENDING'
                                  : 'ASCENDING';

                              setSort({
                                field: 'END_DATE',
                                direction: direction,
                              });
                            }}
                          >
                            <Box className={classes.columnHeader}>
                              <Typography>End Date</Typography>

                              <TableSortLabel
                                active={sort.field === 'END_DATE'}
                                direction={
                                  sort.direction === 'ASCENDING'
                                    ? 'asc'
                                    : 'desc'
                                }
                              ></TableSortLabel>
                            </Box>
                          </TableCell>
                          <TableCell
                            className={classes.cell}
                            onClick={() => {
                              const direction =
                                sort.direction === 'ASCENDING'
                                  ? 'DESCENDING'
                                  : 'ASCENDING';

                              setSort({
                                field: 'DAYS',
                                direction: direction,
                              });
                            }}
                          >
                            <Box className={classes.columnHeader}>
                              <Typography>Days</Typography>

                              <TableSortLabel
                                active={sort.field === 'DAYS'}
                                direction={
                                  sort.direction === 'ASCENDING'
                                    ? 'asc'
                                    : 'desc'
                                }
                              ></TableSortLabel>
                            </Box>
                          </TableCell>

                          <TableCell
                            className={classes.cell}
                            onClick={() => {
                              const direction =
                                sort.direction === 'ASCENDING'
                                  ? 'DESCENDING'
                                  : 'ASCENDING';

                              setSort({
                                field: 'STATUS',
                                direction: direction,
                              });
                            }}
                          >
                            <Box className={classes.columnHeader}>
                              <Typography>Status</Typography>

                              <TableSortLabel
                                active={sort.field === 'STATUS'}
                                direction={
                                  sort.direction === 'ASCENDING'
                                    ? 'asc'
                                    : 'desc'
                                }
                              ></TableSortLabel>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {fragmentNode?.leaves.edges.map((leave) => {
                        return (
                          <TableRow>
                            <TableCell>{leave.node.typeDescription}</TableCell>
                            <TableCell>{leave.node.startDate}</TableCell>
                            <TableCell>{leave.node.endDate}</TableCell>
                            <TableCell>{leave.node.days}</TableCell>
                            <TableCell>{leave.node.status}</TableCell>
                          </TableRow>
                        );
                      })}
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      rowsPerPage={rowsPerPage}
                      component="span"
                      count={
                        fragmentNode?.leaves.total
                          ? fragmentNode?.leaves.total
                          : 0
                      }
                      page={page}
                      onChangePage={(_event, pageNumber) => {
                        setPage(pageNumber);
                      }}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </TableContainer>
                </Form>
              </Box>
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
}

export function LeavesPageWrapper() {
  const {auth} = useAuthContext();

  const navigate = useNavigate();

  React.useEffect(() => {
    if (!auth.authenticated) {
      navigate('/login');
    }
  }, [navigate, auth.authenticated]);

  const {props} = useQuery<LeavesPageQuery>(query);
  if (!props) {
    return <> </>;
  }
  return <LeavesPage node={props?.viewer.user} />;
}
