/* tslint:disable */
/* eslint-disable */

import {ConcreteRequest} from 'relay-runtime';
import {FragmentRefs} from 'relay-runtime';
export type SaveAddressInput = {
  clientMutationId?: string | null;
  id?: string | null;
  building?: string | null;
  street: string;
  city: string;
  postcode: string;
  province: string;
  country: string;
  preferred: boolean;
};
export type AddEditAddressPageMutationVariables = {
  input: SaveAddressInput;
};
export type AddEditAddressPageMutationResponse = {
  readonly saveAddress: {
    readonly clientMutationId: string | null;
    readonly user: {
      readonly id: string;
      readonly ' $fragmentRefs': FragmentRefs<'ProfilePage_user'>;
    };
  };
};
export type AddEditAddressPageMutation = {
  readonly response: AddEditAddressPageMutationResponse;
  readonly variables: AddEditAddressPageMutationVariables;
};

/*
mutation AddEditAddressPageMutation(
  $input: SaveAddressInput!
) {
  saveAddress(input: $input) {
    clientMutationId
    user {
      id
      ...ProfilePage_user
    }
  }
}

fragment ProfilePage_user on User {
  displayName
  email
  firstName
  id
  lastName
  mobileNumber
  profileImageUri
  username
  dateOfBirth
  incomeTaxNumber
  personalReferenceType
  personalReferenceValue
  addresses {
    id
    building
    street
    city
    postcode
    province
    country
    preferred
    prettyFormat
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'input',
        type: 'SaveAddressInput!',
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'input',
        variableName: 'input',
      },
    ],
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'clientMutationId',
      storageKey: null,
    },
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'AddEditAddressPageMutation',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'SaveAddressPayload',
          kind: 'LinkedField',
          name: 'saveAddress',
          plural: false,
          selections: [
            v2 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: 'User',
              kind: 'LinkedField',
              name: 'user',
              plural: false,
              selections: [
                v3 /*: any*/,
                {
                  args: null,
                  kind: 'FragmentSpread',
                  name: 'ProfilePage_user',
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Mutation',
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'AddEditAddressPageMutation',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'SaveAddressPayload',
          kind: 'LinkedField',
          name: 'saveAddress',
          plural: false,
          selections: [
            v2 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: 'User',
              kind: 'LinkedField',
              name: 'user',
              plural: false,
              selections: [
                v3 /*: any*/,
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'displayName',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'email',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'firstName',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'lastName',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'mobileNumber',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'profileImageUri',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'username',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'dateOfBirth',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'incomeTaxNumber',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'personalReferenceType',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'personalReferenceValue',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  concreteType: 'Address',
                  kind: 'LinkedField',
                  name: 'addresses',
                  plural: true,
                  selections: [
                    v3 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'building',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'street',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'city',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'postcode',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'province',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'country',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'preferred',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'prettyFormat',
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      id: null,
      metadata: {},
      name: 'AddEditAddressPageMutation',
      operationKind: 'mutation',
      text:
        'mutation AddEditAddressPageMutation(\n  $input: SaveAddressInput!\n) {\n  saveAddress(input: $input) {\n    clientMutationId\n    user {\n      id\n      ...ProfilePage_user\n    }\n  }\n}\n\nfragment ProfilePage_user on User {\n  displayName\n  email\n  firstName\n  id\n  lastName\n  mobileNumber\n  profileImageUri\n  username\n  dateOfBirth\n  incomeTaxNumber\n  personalReferenceType\n  personalReferenceValue\n  addresses {\n    id\n    building\n    street\n    city\n    postcode\n    province\n    country\n    preferred\n    prettyFormat\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'a7fbac01d3a670a5467a761d05531d79';
export default node;
