import React from 'react';
import {
  Box,
  Button,
  Link,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import {Form, Formik} from 'formik';
import {useNavigate} from 'react-router';
import * as yup from 'yup';
import {FTextField} from '../../modules/FMaterial/FTextfield/FTextField';
import FormItem from '../../atoms/FormItem';
import graphql from 'babel-plugin-relay/macro';
import {useMutation} from 'relay-hooks';
import {ForgotPasswordPageMutation} from '@ssquared/sep/web/__generated__/ForgotPasswordPageMutation.graphql';
import {Toast} from '@ssquared/sep/web/modules/Toast';

interface EmailType {
  email: string;
}

const validationSchema = yup.object().shape({
  email: yup.string().required('Email or username is required'),
});

export function ForgotPasswordPage() {
  const navigate = useNavigate();

  const [mutation, {loading}] = useMutation<ForgotPasswordPageMutation>(graphql`
    mutation ForgotPasswordPageMutation($input: RequestPasswordResetInput!) {
      requestPasswordReset(input: $input) {
        clientMutationId
        emailAddress
      }
    }
  `);
  return (
    <Box display="flex" justifyContent="center" pl={5}>
      <Formik<EmailType>
        validationSchema={validationSchema}
        initialValues={{email: ''}}
        onSubmit={async ({email}) => {
          try {
            const response = await mutation({
              variables: {
                input: {
                  clientId: email,
                },
              },
            });

            if (response) {
              Toast('success', 'Your request was successfully submitted');
            }
          } catch (error) {
            Toast('error', 'Failed, please try again');
          }
        }}
      >
        {({submitForm}) => {
          return (
            <Box width="100%">
              <Form
                style={{display: 'flex', justifyContent: 'center'}}
                onKeyDown={(event) => {
                  if (event.keyCode === 13) {
                    event.preventDefault();
                    submitForm();
                  }
                }}
              >
                <Box width="500px" display="flex" flexDirection="column">
                  <Box pb={2} pl={2} maxWidth="500px" textAlign="center">
                    <Typography variant="h5">Forgot Password</Typography>
                  </Box>
                  <FormItem>
                    <FTextField
                      fullWidth
                      field={{name: 'email'}}
                      label="Email / Username"
                    />
                  </FormItem>

                  <FormItem>
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      fullWidth
                      onClick={() => {
                        submitForm();
                      }}
                    >
                      {loading ? (
                        <CircularProgress style={{color: 'white'}} />
                      ) : (
                        'Request New Password'
                      )}
                    </Button>
                  </FormItem>
                  <Box display="flex" justifyContent="center">
                    <Link
                      color="secondary"
                      component="button"
                      onClick={() => {
                        navigate('/login');
                      }}
                    >
                      Go Back To Sign In
                    </Link>
                  </Box>
                </Box>
              </Form>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
}
