/* tslint:disable */
/* eslint-disable */

import {ConcreteRequest} from 'relay-runtime';
import {FragmentRefs} from 'relay-runtime';
export type TaskPageQueryVariables = {
  search?: string | null;
  first?: number | null;
  after?: string | null;
};
export type TaskPageQueryResponse = {
  readonly viewer: {
    readonly user: {
      readonly ' $fragmentRefs': FragmentRefs<'TaskPage_tasks'>;
    } | null;
  };
};
export type TaskPageQuery = {
  readonly response: TaskPageQueryResponse;
  readonly variables: TaskPageQueryVariables;
};

/*
query TaskPageQuery(
  $search: String
  $first: Int
  $after: String
) {
  viewer {
    user {
      ...TaskPage_tasks
      id
    }
  }
}

fragment TaskPage_tasks on User {
  id
  firstName
  lastName
  email
  mobileNumber
  tasks(q: $search, first: $first, after: $after) {
    total
    edges {
      node {
        id
        description
        dateCreated
        taskId
        taskType
        time
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'search',
        type: 'String',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'first',
        type: 'Int',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'after',
        type: 'String',
      },
    ],
    v1 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'TaskPageQuery',
      selections: [
        {
          alias: null,
          args: null,
          concreteType: 'Viewer',
          kind: 'LinkedField',
          name: 'viewer',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'User',
              kind: 'LinkedField',
              name: 'user',
              plural: false,
              selections: [
                {
                  args: null,
                  kind: 'FragmentSpread',
                  name: 'TaskPage_tasks',
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Query',
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'TaskPageQuery',
      selections: [
        {
          alias: null,
          args: null,
          concreteType: 'Viewer',
          kind: 'LinkedField',
          name: 'viewer',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'User',
              kind: 'LinkedField',
              name: 'user',
              plural: false,
              selections: [
                v1 /*: any*/,
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'firstName',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'lastName',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'email',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'mobileNumber',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: [
                    {
                      kind: 'Variable',
                      name: 'after',
                      variableName: 'after',
                    },
                    {
                      kind: 'Variable',
                      name: 'first',
                      variableName: 'first',
                    },
                    {
                      kind: 'Variable',
                      name: 'q',
                      variableName: 'search',
                    },
                  ],
                  concreteType: 'TaskConnection',
                  kind: 'LinkedField',
                  name: 'tasks',
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'total',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: 'TaskConnectionEdge',
                      kind: 'LinkedField',
                      name: 'edges',
                      plural: true,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          concreteType: 'Task',
                          kind: 'LinkedField',
                          name: 'node',
                          plural: false,
                          selections: [
                            v1 /*: any*/,
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'description',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'dateCreated',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'taskId',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'taskType',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'time',
                              storageKey: null,
                            },
                          ],
                          storageKey: null,
                        },
                      ],
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      id: null,
      metadata: {},
      name: 'TaskPageQuery',
      operationKind: 'query',
      text:
        'query TaskPageQuery(\n  $search: String\n  $first: Int\n  $after: String\n) {\n  viewer {\n    user {\n      ...TaskPage_tasks\n      id\n    }\n  }\n}\n\nfragment TaskPage_tasks on User {\n  id\n  firstName\n  lastName\n  email\n  mobileNumber\n  tasks(q: $search, first: $first, after: $after) {\n    total\n    edges {\n      node {\n        id\n        description\n        dateCreated\n        taskId\n        taskType\n        time\n      }\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'e982cc2b616787b5ad25f8b7656b1a1a';
export default node;
