import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';
import {Route, Routes} from 'react-router-dom';
import {HeaderWrapper} from '../Header/Header';
import {SignInPage} from '../../pages/SignInPage/SignInPage';
import {ForgotPasswordPage} from '../../pages/ForgotPasswordPage/ForgotPasswordPage';
import {SignUpPage} from '../../pages/SignUpPage/SignUpPage';
import {ProfilePageWrapper} from '../../pages/ProfilePage/ProfilePage';
import {ResetPasswordPage} from '../../pages/ResetPasswordPage/ResetPasswordPage';
import {CaptureTimesheetPage} from '../../pages/TimeSheet/CaptureTimesheetPage';
import {TimesheetsPageWrapper} from '../../pages/TimeSheet/TimesheetsPage';
import {ApplyForLeavePage} from '../../pages/Leave/ApplyForLeavePage';
import {LeavesPageWrapper} from '../../pages/Leave/LeavesPage';
import {TaskPageWrapper} from '../../pages/Task/TaskPage';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    paddingRight: 100,
    paddingLeft: 100,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 10,
      overflowX: 'hidden',
      paddingRight: 10,
    },
  },
}));

export function Navigation() {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.root}>
        <Route element={<HeaderWrapper />} />
        <Routes>
          <Route path="/login" element={<SignInPage />} />
          <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/profile" element={<ProfilePageWrapper />} />
          <Route path="/resetpassword" element={<ResetPasswordPage />} />
          <Route path="/capturetimesheet" element={<CaptureTimesheetPage />} />
          <Route path="/timesheets" element={<TimesheetsPageWrapper />} />
          <Route path="/leaveapplication" element={<ApplyForLeavePage />} />
          <Route path="/leaves" element={<LeavesPageWrapper />} />
          <Route path="/tasks" element={<TaskPageWrapper />} />
        </Routes>
      </Box>
    </>
  );
}
