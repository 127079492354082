import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {ConfigService} from '@stackworx/react';
import useLocalStorage from 'react-use/lib/useLocalStorage';
import jwtDecode from 'jwt-decode';
import {UserRole} from 'types';

export interface AuthImageObject {
  id: string;
  uri: string;
  title: string;
  altText?: string;
}

function parseRoles(roles: string[]) {
  return roles.map((role) => {
    switch (role) {
      case UserRole.SYSADMIN:
        return UserRole.SYSADMIN;
      case UserRole.ADMIN:
        return UserRole.ADMIN;
      default:
        return UserRole.UNKNOWN;
    }
  });
}

export type AuthContextType =
  | {
      token: string;
      authenticated: true;
      roles?: UserRole[];
    }
  | {
      authenticated: false;
    };

export interface AuthContextStateType {
  auth: AuthContextType;
  handleLogin: (username: string, password: string) => Promise<Response>;
  handleLogout: () => void;
  hasRole(role: UserRole): boolean;
}

export const AuthContext = createContext<AuthContextStateType>({
  auth: {authenticated: false},
  handleLogin: async () => new Promise(() => {}),
  handleLogout: () => {},
  hasRole: () => true,
});
interface Props {
  children: JSX.Element;
}
export function AuthContextProvider({children}: Props) {
  const loggingOut = useRef(false);
  const authTokenLoaded = React.useRef<boolean>(false);

  const [localAuth, setLocalAuth] = useLocalStorage<AuthContextType>('auth', {
    authenticated: false,
  });

  const [auth, setAuth] = useState<AuthContextType>(() => {
    authTokenLoaded.current = true;
    return localAuth;
  });

  const handleLogin = React.useCallback(
    async function (username: string, password: string) {
      loggingOut.current = false;

      const body = new URLSearchParams();
      body.set('grant_type', 'client_credentials');

      // TODO only throws if the server cant be reached
      const result = await fetch(`${ConfigService.serverUri}/oauth/token`, {
        headers: {
          Authorization: `Basic ${btoa(`${username}:${password}`)}`,
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        method: 'POST',
        body,
      });

      if (result.ok) {
        const responseObject = await result.json();
        const token = responseObject.access_token;

        const decoded = jwtDecode(token) as {
          authorities: string[];
        };

        const newAuth: AuthContextType = {
          authenticated: true,
          token,
          roles: decoded.authorities
            ? parseRoles(decoded.authorities)
            : [UserRole.UNKNOWN],
        };

        setAuth(newAuth);
        setLocalAuth(newAuth);
        return result;
      } else {
        return result;
      }
      // TODO handle timeout
    },
    [setLocalAuth]
  );

  const handleLogout = useCallback(() => {
    if (auth.authenticated) {
      setAuth({authenticated: false});
      setLocalAuth({authenticated: false});
    }
  }, [auth.authenticated, setLocalAuth]);

  useEffect(() => {
    if (loggingOut.current) {
      handleLogout();
      loggingOut.current = false;
    }
  }, [loggingOut, handleLogout]);

  useEffect(() => {
    let innerAuth: AuthContextType = {authenticated: false};

    // TODO: Localize expiry
    if (localAuth !== null) {
      innerAuth = localAuth;

      setAuth(innerAuth);
    }
  }, [localAuth, auth]);

  const hasRole = React.useCallback(
    (role: UserRole) => {
      if (!auth.authenticated) {
        return false;
      }
      if (
        auth.roles?.indexOf(UserRole.SYSADMIN) !== -1 ||
        auth.roles?.indexOf(role) !== -1
      ) {
        return true;
      }

      return false;
    },
    [auth]
  );

  const value = React.useMemo(
    () => ({auth, handleLogin, handleLogout, hasRole}),
    [auth, handleLogin, handleLogout, hasRole]
  );

  // Prevent double render before auth token loaded
  if (!authTokenLoaded.current) {
    return null;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthContextProvider;
export const useAuthContext = () => useContext(AuthContext);
