/* tslint:disable */
/* eslint-disable */

import {ConcreteRequest} from 'relay-runtime';
export type ActionLeaveQueryVariables = {
  taskId?: number | null;
};
export type ActionLeaveQueryResponse = {
  readonly viewer: {
    readonly viewLeaveRequestDetails: {
      readonly days: number;
      readonly startDate: string;
      readonly endDate: string;
      readonly leaveType: string;
      readonly requestingUser: string;
      readonly taskId: number | null;
    } | null;
  };
};
export type ActionLeaveQuery = {
  readonly response: ActionLeaveQueryResponse;
  readonly variables: ActionLeaveQueryVariables;
};

/*
query ActionLeaveQuery(
  $taskId: Int
) {
  viewer {
    viewLeaveRequestDetails(taskId: $taskId) {
      days
      startDate
      endDate
      leaveType
      requestingUser
      taskId
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'taskId',
        type: 'Int',
      },
    ],
    v1 = [
      {
        alias: null,
        args: null,
        concreteType: 'Viewer',
        kind: 'LinkedField',
        name: 'viewer',
        plural: false,
        selections: [
          {
            alias: null,
            args: [
              {
                kind: 'Variable',
                name: 'taskId',
                variableName: 'taskId',
              },
            ],
            concreteType: 'LeaveRequestDetails',
            kind: 'LinkedField',
            name: 'viewLeaveRequestDetails',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'days',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'startDate',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'endDate',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'leaveType',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'requestingUser',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'taskId',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'ActionLeaveQuery',
      selections: v1 /*: any*/,
      type: 'Query',
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'ActionLeaveQuery',
      selections: v1 /*: any*/,
    },
    params: {
      id: null,
      metadata: {},
      name: 'ActionLeaveQuery',
      operationKind: 'query',
      text:
        'query ActionLeaveQuery(\n  $taskId: Int\n) {\n  viewer {\n    viewLeaveRequestDetails(taskId: $taskId) {\n      days\n      startDate\n      endDate\n      leaveType\n      requestingUser\n      taskId\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'ba592b372f1920fc4da924e80aae6592';
export default node;
