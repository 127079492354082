/* tslint:disable */
/* eslint-disable */

import {ConcreteRequest} from 'relay-runtime';
export type DownloadTimesheetInput = {
  clientMutationId?: string | null;
  userTimesheetId: string;
};
export type TimesheetsPageDownloadMutationVariables = {
  input: DownloadTimesheetInput;
};
export type TimesheetsPageDownloadMutationResponse = {
  readonly downloadTimesheet: {
    readonly url: string;
  } | null;
};
export type TimesheetsPageDownloadMutation = {
  readonly response: TimesheetsPageDownloadMutationResponse;
  readonly variables: TimesheetsPageDownloadMutationVariables;
};

/*
mutation TimesheetsPageDownloadMutation(
  $input: DownloadTimesheetInput!
) {
  downloadTimesheet(input: $input) {
    url
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'input',
        type: 'DownloadTimesheetInput!',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'input',
            variableName: 'input',
          },
        ],
        concreteType: 'DownloadTimesheetPayload',
        kind: 'LinkedField',
        name: 'downloadTimesheet',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'url',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'TimesheetsPageDownloadMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'TimesheetsPageDownloadMutation',
      selections: v1 /*: any*/,
    },
    params: {
      id: null,
      metadata: {},
      name: 'TimesheetsPageDownloadMutation',
      operationKind: 'mutation',
      text:
        'mutation TimesheetsPageDownloadMutation(\n  $input: DownloadTimesheetInput!\n) {\n  downloadTimesheet(input: $input) {\n    url\n  }\n}\n',
    },
  };
})();
(node as any).hash = '9c6f8416486b3cbce923a269af9f6c0c';
export default node;
