/* tslint:disable */
/* eslint-disable */

import {ConcreteRequest} from 'relay-runtime';
import {FragmentRefs} from 'relay-runtime';
export type ProfilePageRefetchQueryVariables = {
  id: string;
};
export type ProfilePageRefetchQueryResponse = {
  readonly node: {
    readonly ' $fragmentRefs': FragmentRefs<'ProfilePage_user'>;
  } | null;
};
export type ProfilePageRefetchQuery = {
  readonly response: ProfilePageRefetchQueryResponse;
  readonly variables: ProfilePageRefetchQueryVariables;
};

/*
query ProfilePageRefetchQuery(
  $id: ID!
) {
  node(id: $id) {
    __typename
    ...ProfilePage_user
    id
  }
}

fragment ProfilePage_user on User {
  displayName
  email
  firstName
  id
  lastName
  mobileNumber
  profileImageUri
  username
  dateOfBirth
  incomeTaxNumber
  personalReferenceType
  personalReferenceValue
  addresses {
    id
    building
    street
    city
    postcode
    province
    country
    preferred
    prettyFormat
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'id',
        type: 'ID!',
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'id',
      },
    ],
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'ProfilePageRefetchQuery',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: null,
          kind: 'LinkedField',
          name: 'node',
          plural: false,
          selections: [
            {
              args: null,
              kind: 'FragmentSpread',
              name: 'ProfilePage_user',
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Query',
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'ProfilePageRefetchQuery',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: null,
          kind: 'LinkedField',
          name: 'node',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: '__typename',
              storageKey: null,
            },
            v2 /*: any*/,
            {
              kind: 'InlineFragment',
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'displayName',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'email',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'firstName',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'lastName',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'mobileNumber',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'profileImageUri',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'username',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'dateOfBirth',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'incomeTaxNumber',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'personalReferenceType',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'personalReferenceValue',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  concreteType: 'Address',
                  kind: 'LinkedField',
                  name: 'addresses',
                  plural: true,
                  selections: [
                    v2 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'building',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'street',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'city',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'postcode',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'province',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'country',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'preferred',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'prettyFormat',
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
              ],
              type: 'User',
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      id: null,
      metadata: {
        derivedFrom: 'ProfilePage_user',
        isRefetchableQuery: true,
      },
      name: 'ProfilePageRefetchQuery',
      operationKind: 'query',
      text:
        'query ProfilePageRefetchQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...ProfilePage_user\n    id\n  }\n}\n\nfragment ProfilePage_user on User {\n  displayName\n  email\n  firstName\n  id\n  lastName\n  mobileNumber\n  profileImageUri\n  username\n  dateOfBirth\n  incomeTaxNumber\n  personalReferenceType\n  personalReferenceValue\n  addresses {\n    id\n    building\n    street\n    city\n    postcode\n    province\n    country\n    preferred\n    prettyFormat\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'b96af739f63971daf19b360c81fee168';
export default node;
