/* tslint:disable */
/* eslint-disable */

import {ReaderFragment} from 'relay-runtime';
import {FragmentRefs} from 'relay-runtime';
export type TaskPage_tasks = {
  readonly id: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly mobileNumber: string | null;
  readonly tasks: {
    readonly total: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly description: string;
        readonly dateCreated: string;
        readonly taskId: number;
        readonly taskType: string;
        readonly time: string | null;
      };
    }>;
  };
  readonly ' $refType': 'TaskPage_tasks';
};
export type TaskPage_tasks$data = TaskPage_tasks;
export type TaskPage_tasks$key = {
  readonly ' $data'?: TaskPage_tasks$data;
  readonly ' $fragmentRefs': FragmentRefs<'TaskPage_tasks'>;
};

const node: ReaderFragment = (function () {
  var v0 = {
    alias: null,
    args: null,
    kind: 'ScalarField',
    name: 'id',
    storageKey: null,
  };
  return {
    argumentDefinitions: [
      {
        kind: 'RootArgument',
        name: 'search',
        type: 'String',
      },
      {
        kind: 'RootArgument',
        name: 'first',
        type: 'Int',
      },
      {
        kind: 'RootArgument',
        name: 'after',
        type: 'String',
      },
    ],
    kind: 'Fragment',
    metadata: {
      refetch: {
        connection: null,
        fragmentPathInResult: ['node'],
        operation: require('./TaskPageRefetchQuery.graphql.ts'),
        identifierField: 'id',
      },
    },
    name: 'TaskPage_tasks',
    selections: [
      v0 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'firstName',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'lastName',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'email',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'mobileNumber',
        storageKey: null,
      },
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'after',
            variableName: 'after',
          },
          {
            kind: 'Variable',
            name: 'first',
            variableName: 'first',
          },
          {
            kind: 'Variable',
            name: 'q',
            variableName: 'search',
          },
        ],
        concreteType: 'TaskConnection',
        kind: 'LinkedField',
        name: 'tasks',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'total',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'TaskConnectionEdge',
            kind: 'LinkedField',
            name: 'edges',
            plural: true,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: 'Task',
                kind: 'LinkedField',
                name: 'node',
                plural: false,
                selections: [
                  v0 /*: any*/,
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'description',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'dateCreated',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'taskId',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'taskType',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'time',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ],
    type: 'User',
  };
})();
(node as any).hash = 'e199b10b9433f40f61d12e9d67783ae5';
export default node;
