/* tslint:disable */
/* eslint-disable */

import {ReaderFragment} from 'relay-runtime';
import {FragmentRefs} from 'relay-runtime';
export type ProfilePage_user = {
  readonly displayName: string;
  readonly email: string;
  readonly firstName: string;
  readonly id: string;
  readonly lastName: string;
  readonly mobileNumber: string | null;
  readonly profileImageUri: string;
  readonly username: string;
  readonly dateOfBirth: string | null;
  readonly incomeTaxNumber: string | null;
  readonly personalReferenceType: string | null;
  readonly personalReferenceValue: string | null;
  readonly addresses: ReadonlyArray<{
    readonly id: string;
    readonly building: string | null;
    readonly street: string;
    readonly city: string;
    readonly postcode: string;
    readonly province: string;
    readonly country: string;
    readonly preferred: boolean;
    readonly prettyFormat: string;
  }>;
  readonly ' $refType': 'ProfilePage_user';
};
export type ProfilePage_user$data = ProfilePage_user;
export type ProfilePage_user$key = {
  readonly ' $data'?: ProfilePage_user$data;
  readonly ' $fragmentRefs': FragmentRefs<'ProfilePage_user'>;
};

const node: ReaderFragment = (function () {
  var v0 = {
    alias: null,
    args: null,
    kind: 'ScalarField',
    name: 'id',
    storageKey: null,
  };
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: {
      refetch: {
        connection: null,
        fragmentPathInResult: ['node'],
        operation: require('./ProfilePageRefetchQuery.graphql.ts'),
        identifierField: 'id',
      },
    },
    name: 'ProfilePage_user',
    selections: [
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'displayName',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'email',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'firstName',
        storageKey: null,
      },
      v0 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'lastName',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'mobileNumber',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'profileImageUri',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'username',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'dateOfBirth',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'incomeTaxNumber',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'personalReferenceType',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'personalReferenceValue',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'Address',
        kind: 'LinkedField',
        name: 'addresses',
        plural: true,
        selections: [
          v0 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'building',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'street',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'city',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'postcode',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'province',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'country',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'preferred',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'prettyFormat',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ],
    type: 'User',
  };
})();
(node as any).hash = 'b96af739f63971daf19b360c81fee168';
export default node;
