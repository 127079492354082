/* tslint:disable */
/* eslint-disable */

import {ConcreteRequest} from 'relay-runtime';
export type SaveUserTimesheetInput = {
  clientMutationId?: string | null;
  userTimesheet?: UserTimesheetInput | null;
  timesheetId: string;
};
export type UserTimesheetInput = {
  id?: string | null;
  timesheetId: string;
  userId?: string | null;
  totalActualHours?: number | null;
  lines: Array<UserTimesheetLineInput>;
};
export type UserTimesheetLineInput = {
  id: string;
  userTimesheetId?: string | null;
  position: number;
  description?: string | null;
  actualHours?: number | null;
};
export type EditTimesheetMutationVariables = {
  input: SaveUserTimesheetInput;
};
export type EditTimesheetMutationResponse = {
  readonly saveUserTimesheet: {
    readonly clientMutationId: string | null;
  };
};
export type EditTimesheetMutation = {
  readonly response: EditTimesheetMutationResponse;
  readonly variables: EditTimesheetMutationVariables;
};

/*
mutation EditTimesheetMutation(
  $input: SaveUserTimesheetInput!
) {
  saveUserTimesheet(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'input',
        type: 'SaveUserTimesheetInput!',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'input',
            variableName: 'input',
          },
        ],
        concreteType: 'SaveUserTimesheetPayload',
        kind: 'LinkedField',
        name: 'saveUserTimesheet',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'clientMutationId',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'EditTimesheetMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'EditTimesheetMutation',
      selections: v1 /*: any*/,
    },
    params: {
      id: null,
      metadata: {},
      name: 'EditTimesheetMutation',
      operationKind: 'mutation',
      text:
        'mutation EditTimesheetMutation(\n  $input: SaveUserTimesheetInput!\n) {\n  saveUserTimesheet(input: $input) {\n    clientMutationId\n  }\n}\n',
    },
  };
})();
(node as any).hash = '50dc7a9b35af66c6168597e0470ecfcb';
export default node;
