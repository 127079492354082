/* tslint:disable */
/* eslint-disable */

import {ConcreteRequest} from 'relay-runtime';
export type CalculateLeaveDaysInput = {
  startDate: string;
  endDate: string;
};
export type ApplyForLeavePageDaysMutationVariables = {
  input: CalculateLeaveDaysInput;
};
export type ApplyForLeavePageDaysMutationResponse = {
  readonly calculateLeaveDays: {
    readonly daysBetween: number;
    readonly decisions: ReadonlyArray<string>;
  };
};
export type ApplyForLeavePageDaysMutation = {
  readonly response: ApplyForLeavePageDaysMutationResponse;
  readonly variables: ApplyForLeavePageDaysMutationVariables;
};

/*
mutation ApplyForLeavePageDaysMutation(
  $input: CalculateLeaveDaysInput!
) {
  calculateLeaveDays(input: $input) {
    daysBetween
    decisions
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'input',
        type: 'CalculateLeaveDaysInput!',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'input',
            variableName: 'input',
          },
        ],
        concreteType: 'CalculateLeaveDaysPayload',
        kind: 'LinkedField',
        name: 'calculateLeaveDays',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'daysBetween',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'decisions',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'ApplyForLeavePageDaysMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'ApplyForLeavePageDaysMutation',
      selections: v1 /*: any*/,
    },
    params: {
      id: null,
      metadata: {},
      name: 'ApplyForLeavePageDaysMutation',
      operationKind: 'mutation',
      text:
        'mutation ApplyForLeavePageDaysMutation(\n  $input: CalculateLeaveDaysInput!\n) {\n  calculateLeaveDays(input: $input) {\n    daysBetween\n    decisions\n  }\n}\n',
    },
  };
})();
(node as any).hash = '00bc9aeb505f11fb0296d92409b5e6e0';
export default node;
