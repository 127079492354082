/* tslint:disable */
/* eslint-disable */

import {ConcreteRequest} from 'relay-runtime';
import {FragmentRefs} from 'relay-runtime';
export type ProfilePageQueryVariables = {};
export type ProfilePageQueryResponse = {
  readonly viewer: {
    readonly user: {
      readonly ' $fragmentRefs': FragmentRefs<'ProfilePage_user'>;
    } | null;
  };
};
export type ProfilePageQuery = {
  readonly response: ProfilePageQueryResponse;
  readonly variables: ProfilePageQueryVariables;
};

/*
query ProfilePageQuery {
  viewer {
    user {
      ...ProfilePage_user
      id
    }
  }
}

fragment ProfilePage_user on User {
  displayName
  email
  firstName
  id
  lastName
  mobileNumber
  profileImageUri
  username
  dateOfBirth
  incomeTaxNumber
  personalReferenceType
  personalReferenceValue
  addresses {
    id
    building
    street
    city
    postcode
    province
    country
    preferred
    prettyFormat
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = {
    alias: null,
    args: null,
    kind: 'ScalarField',
    name: 'id',
    storageKey: null,
  };
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'ProfilePageQuery',
      selections: [
        {
          alias: null,
          args: null,
          concreteType: 'Viewer',
          kind: 'LinkedField',
          name: 'viewer',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'User',
              kind: 'LinkedField',
              name: 'user',
              plural: false,
              selections: [
                {
                  args: null,
                  kind: 'FragmentSpread',
                  name: 'ProfilePage_user',
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Query',
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'ProfilePageQuery',
      selections: [
        {
          alias: null,
          args: null,
          concreteType: 'Viewer',
          kind: 'LinkedField',
          name: 'viewer',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'User',
              kind: 'LinkedField',
              name: 'user',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'displayName',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'email',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'firstName',
                  storageKey: null,
                },
                v0 /*: any*/,
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'lastName',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'mobileNumber',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'profileImageUri',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'username',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'dateOfBirth',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'incomeTaxNumber',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'personalReferenceType',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'personalReferenceValue',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  concreteType: 'Address',
                  kind: 'LinkedField',
                  name: 'addresses',
                  plural: true,
                  selections: [
                    v0 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'building',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'street',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'city',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'postcode',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'province',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'country',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'preferred',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'prettyFormat',
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      id: null,
      metadata: {},
      name: 'ProfilePageQuery',
      operationKind: 'query',
      text:
        'query ProfilePageQuery {\n  viewer {\n    user {\n      ...ProfilePage_user\n      id\n    }\n  }\n}\n\nfragment ProfilePage_user on User {\n  displayName\n  email\n  firstName\n  id\n  lastName\n  mobileNumber\n  profileImageUri\n  username\n  dateOfBirth\n  incomeTaxNumber\n  personalReferenceType\n  personalReferenceValue\n  addresses {\n    id\n    building\n    street\n    city\n    postcode\n    province\n    country\n    preferred\n    prettyFormat\n  }\n}\n',
    },
  };
})();
(node as any).hash = '1734582f9c99df5b1635a35be059b13d';
export default node;
