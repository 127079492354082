/* tslint:disable */
/* eslint-disable */

import {ConcreteRequest} from 'relay-runtime';
import {FragmentRefs} from 'relay-runtime';
export type TimesheetsPageQueryVariables = {
  search?: string | null;
  first?: number | null;
  after?: string | null;
};
export type TimesheetsPageQueryResponse = {
  readonly viewer: {
    readonly user: {
      readonly ' $fragmentRefs': FragmentRefs<'TimesheetsPage_timesheets'>;
    } | null;
  };
};
export type TimesheetsPageQuery = {
  readonly response: TimesheetsPageQueryResponse;
  readonly variables: TimesheetsPageQueryVariables;
};

/*
query TimesheetsPageQuery(
  $search: String
  $first: Int
  $after: String
) {
  viewer {
    user {
      ...TimesheetsPage_timesheets
      id
    }
  }
}

fragment TimesheetsPage_timesheets on User {
  id
  firstName
  lastName
  email
  mobileNumber
  timesheets(q: $search, first: $first, after: $after, sort: {field: ID, direction: DESCENDING}) {
    total
    edges {
      node {
        id
        totalActualHours
        userId
        lines {
          id
          actualHours
          description
          position
          userTimesheetId
        }
        timesheet {
          id
          billableHours
          description
          name
          startDate
          endDate
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'search',
        type: 'String',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'first',
        type: 'Int',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'after',
        type: 'String',
      },
    ],
    v1 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'description',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'TimesheetsPageQuery',
      selections: [
        {
          alias: null,
          args: null,
          concreteType: 'Viewer',
          kind: 'LinkedField',
          name: 'viewer',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'User',
              kind: 'LinkedField',
              name: 'user',
              plural: false,
              selections: [
                {
                  args: null,
                  kind: 'FragmentSpread',
                  name: 'TimesheetsPage_timesheets',
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Query',
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'TimesheetsPageQuery',
      selections: [
        {
          alias: null,
          args: null,
          concreteType: 'Viewer',
          kind: 'LinkedField',
          name: 'viewer',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'User',
              kind: 'LinkedField',
              name: 'user',
              plural: false,
              selections: [
                v1 /*: any*/,
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'firstName',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'lastName',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'email',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'mobileNumber',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: [
                    {
                      kind: 'Variable',
                      name: 'after',
                      variableName: 'after',
                    },
                    {
                      kind: 'Variable',
                      name: 'first',
                      variableName: 'first',
                    },
                    {
                      kind: 'Variable',
                      name: 'q',
                      variableName: 'search',
                    },
                    {
                      kind: 'Literal',
                      name: 'sort',
                      value: {
                        direction: 'DESCENDING',
                        field: 'ID',
                      },
                    },
                  ],
                  concreteType: 'UserTimesheetConnection',
                  kind: 'LinkedField',
                  name: 'timesheets',
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'total',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: 'UserTimesheetConnectionEdge',
                      kind: 'LinkedField',
                      name: 'edges',
                      plural: true,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          concreteType: 'UserTimesheet',
                          kind: 'LinkedField',
                          name: 'node',
                          plural: false,
                          selections: [
                            v1 /*: any*/,
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'totalActualHours',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'userId',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              concreteType: 'UserTimesheetLine',
                              kind: 'LinkedField',
                              name: 'lines',
                              plural: true,
                              selections: [
                                v1 /*: any*/,
                                {
                                  alias: null,
                                  args: null,
                                  kind: 'ScalarField',
                                  name: 'actualHours',
                                  storageKey: null,
                                },
                                v2 /*: any*/,
                                {
                                  alias: null,
                                  args: null,
                                  kind: 'ScalarField',
                                  name: 'position',
                                  storageKey: null,
                                },
                                {
                                  alias: null,
                                  args: null,
                                  kind: 'ScalarField',
                                  name: 'userTimesheetId',
                                  storageKey: null,
                                },
                              ],
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              concreteType: 'Timesheet',
                              kind: 'LinkedField',
                              name: 'timesheet',
                              plural: false,
                              selections: [
                                v1 /*: any*/,
                                {
                                  alias: null,
                                  args: null,
                                  kind: 'ScalarField',
                                  name: 'billableHours',
                                  storageKey: null,
                                },
                                v2 /*: any*/,
                                {
                                  alias: null,
                                  args: null,
                                  kind: 'ScalarField',
                                  name: 'name',
                                  storageKey: null,
                                },
                                {
                                  alias: null,
                                  args: null,
                                  kind: 'ScalarField',
                                  name: 'startDate',
                                  storageKey: null,
                                },
                                {
                                  alias: null,
                                  args: null,
                                  kind: 'ScalarField',
                                  name: 'endDate',
                                  storageKey: null,
                                },
                              ],
                              storageKey: null,
                            },
                          ],
                          storageKey: null,
                        },
                      ],
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      id: null,
      metadata: {},
      name: 'TimesheetsPageQuery',
      operationKind: 'query',
      text:
        'query TimesheetsPageQuery(\n  $search: String\n  $first: Int\n  $after: String\n) {\n  viewer {\n    user {\n      ...TimesheetsPage_timesheets\n      id\n    }\n  }\n}\n\nfragment TimesheetsPage_timesheets on User {\n  id\n  firstName\n  lastName\n  email\n  mobileNumber\n  timesheets(q: $search, first: $first, after: $after, sort: {field: ID, direction: DESCENDING}) {\n    total\n    edges {\n      node {\n        id\n        totalActualHours\n        userId\n        lines {\n          id\n          actualHours\n          description\n          position\n          userTimesheetId\n        }\n        timesheet {\n          id\n          billableHours\n          description\n          name\n          startDate\n          endDate\n        }\n      }\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'f11c9b2d3dff6716ba43af8698e72a7e';
export default node;
