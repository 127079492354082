import React, {Suspense} from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import AssignmentIcon from '@material-ui/icons/Assignment';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
  withStyles,
} from '@material-ui/core/styles';
import {Avatar, Badge, Box, Button, Typography} from '@material-ui/core';
import {useNavigate} from 'react-router';
import clsx from 'clsx';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import logo from '../../assets/Logo.png';
import {useAuthContext} from '../../context/AuthContext';
import graphql from 'babel-plugin-relay/macro';
import {HeaderQuery} from '../../__generated__/HeaderQuery.graphql';
import {useQuery} from 'relay-hooks';
import {useFragment} from 'relay-hooks/lib';
import {Header_userInformation$key} from '../../__generated__/Header_userInformation.graphql';
import {useScreenSize} from '../../hooks/useScreenSize';

const query = graphql`
  query HeaderQuery(
    $search: String
    $first: Int
    $after: String
    $sort: LeaveSort
  ) {
    viewer {
      user {
        ...Header_userInformation
        ...ProfilePage_user
        ...TaskPage_tasks
        ...LeavesPage_leaves
      }
    }
  }
`;

const fragment = graphql`
  fragment Header_userInformation on User {
    firstName
    profileImageUri
    tasks(q: $search, first: $first, after: $after) {
      total
    }
  }
`;

interface Props {
  node?: Header_userInformation$key | null;
}

const drawerWidth = 300;

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      right: -3,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
      backgroundColor: '#F58023',
    },
  })
)(Badge);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
      marginLeft: 0,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflowX: 'hidden',
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      // [theme.breakpoints.up('sm')]: {
      //     width: theme.spacing(9) + 1,
      // },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    logo: {
      padding: '20 !important',
      '&:hover': {
        backgroundColor: 'white',
        cursor: 'pointer',
        textDecoration: 'none',
      },
    },
  })
);

export function Header({node}: Props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useScreenSize(600);
  const {auth, handleLogout} = useAuthContext();
  const [open, setOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const fragmentNode = useFragment(fragment, node ? node : null);
  // const handleMenuItemClick = (
  //   event: React.MouseEvent<HTMLElement>,
  //   index: number
  // ) => {
  //   setSubSelectedIndex(0);
  //   setSelectedIndex(index);
  // };

  // const handleSubMenuItemClick = (
  //   event: React.MouseEvent<HTMLElement>,
  //   index: number
  // ) => {
  //   setSubSelectedIndex(index);
  // };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleNavigation = (
    location: string,
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    navigate(location);
    setSelectedIndex(index);
    // setSubSelectedIndex(0);
    handleDrawerClose();
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar
          style={{
            paddingLeft: '16px',
            paddingBottom: '10px',
            paddingTop: '10px',
            borderBottom: '5px solid #F58023',
            backgroundColor: 'white',
          }}
        >
          <IconButton
            color="primary"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              navigate('/');
            }}
            className={classes.logo}
          >
            <img style={{width: '150px'}} src={logo} alt="S-Squared Portal" />
          </IconButton>
          <Box display="flex" justifyContent="flex-end" width="100%">
            {auth.authenticated ? (
              <Box>
                {!isMobile ? (
                  <Box display="flex" alignItems="center">
                    <IconButton
                      style={{color: 'white'}}
                      aria-label="Sign Out"
                      onClick={() => {
                        handleLogout();
                        navigate('/login');
                      }}
                    >
                      <MeetingRoomIcon style={{fontSize: '28px'}} />
                    </IconButton>
                  </Box>
                ) : (
                  <Box display="flex" alignItems="center">
                    <Typography style={{color: 'black'}}>
                      Hi, {fragmentNode?.firstName}
                    </Typography>
                    <Button
                      color="primary"
                      style={{marginLeft: '10px'}}
                      onClick={() => {
                        handleLogout();
                        navigate('/login');
                      }}
                    >
                      Sign Out
                    </Button>
                  </Box>
                )}
              </Box>
            ) : (
              <Box>
                {isMobile ? (
                  <Box>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        navigate('/signup');
                      }}
                    >
                      Sign Up
                    </Button>
                    <Button
                      style={{marginLeft: '5px'}}
                      color="secondary"
                      onClick={() => {
                        navigate('/login');
                      }}
                    >
                      Sign In
                    </Button>
                  </Box>
                ) : (
                  <Box>
                    <IconButton
                      style={{color: 'white'}}
                      aria-label="Sign In"
                      onClick={() => {
                        navigate('/signup');
                      }}
                    >
                      <PersonAddIcon style={{fontSize: '28px'}} />
                    </IconButton>
                    <IconButton
                      style={{color: 'white', marginLeft: '10px'}}
                      aria-label="Sign Up"
                      onClick={() => {
                        navigate('/login');
                      }}
                    >
                      <ExitToAppIcon style={{fontSize: '28px'}} />
                    </IconButton>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />

        {auth.authenticated ? (
          <List style={{height: '100%'}}>
            <ListItem
              style={{paddingTop: 25}}
              selected={selectedIndex === 1}
              button
              onClick={(event) => {
                handleNavigation('/timesheets', event, 1);
              }}
            >
              <ListItemIcon>{<EventAvailableIcon />}</ListItemIcon>
              <ListItemText primary="Timesheets" />
            </ListItem>

            {/*<List>*/}
            <ListItem
              selected={selectedIndex === 2}
              button
              onClick={(event) => {
                handleNavigation('/leaves', event, 2);
              }}
            >
              <ListItemIcon>{<EventBusyIcon />}</ListItemIcon>
              <ListItemText primary="Leave" />
            </ListItem>

            <ListItem
              selected={selectedIndex === 3}
              button
              onClick={(event) => {
                handleNavigation('/tasks', event, 3);
              }}
            >
              <ListItemIcon>
                <IconButton aria-label="cart">
                  <StyledBadge
                    badgeContent={fragmentNode?.tasks.total}
                    color="secondary"
                  >
                    <AssignmentIcon />
                  </StyledBadge>
                </IconButton>
              </ListItemIcon>
              <ListItemText primary="Tasks" />
            </ListItem>
            {/*</List>*/}
          </List>
        ) : null}

        {auth.authenticated ? (
          <List style={{display: 'flex', justifyContent: 'flex-end'}}>
            <ListItem
              style={{paddingRight: '48px'}}
              selected={selectedIndex === 6}
              button
              onClick={(event) => {
                handleNavigation('/profile', event, 6);
              }}
            >
              <ListItemIcon>
                <Suspense fallback="Loading..">
                  <Box>
                    <Avatar
                      src={fragmentNode?.profileImageUri || ''}
                      alt={fragmentNode?.profileImageUri || ''}
                    />
                  </Box>
                </Suspense>
              </ListItemIcon>
              <ListItemText primary="Profile" />
            </ListItem>
          </List>
        ) : (
          ''
        )}
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
      </main>
    </div>
  );
}

export function HeaderWrapper() {
  const {props, error} = useQuery<HeaderQuery>(query);
  //error handling
  if (props?.viewer?.user) {
    return <Header node={props.viewer.user} />;
  } else if (error) {
    return <div>{error.message}</div>;
  }
  return <Header />;
}
