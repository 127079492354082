/* tslint:disable */
/* eslint-disable */

import {ConcreteRequest} from 'relay-runtime';
export type TimesheetsPageDownloadLastMonthMutationVariables = {};
export type TimesheetsPageDownloadLastMonthMutationResponse = {
  readonly downloadLastMonthTimesheets: string;
};
export type TimesheetsPageDownloadLastMonthMutation = {
  readonly response: TimesheetsPageDownloadLastMonthMutationResponse;
  readonly variables: TimesheetsPageDownloadLastMonthMutationVariables;
};

/*
mutation TimesheetsPageDownloadLastMonthMutation {
  downloadLastMonthTimesheets
}
*/

const node: ConcreteRequest = (function () {
  var v0 = [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'downloadLastMonthTimesheets',
      storageKey: null,
    },
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'TimesheetsPageDownloadLastMonthMutation',
      selections: v0 /*: any*/,
      type: 'Mutation',
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'TimesheetsPageDownloadLastMonthMutation',
      selections: v0 /*: any*/,
    },
    params: {
      id: null,
      metadata: {},
      name: 'TimesheetsPageDownloadLastMonthMutation',
      operationKind: 'mutation',
      text:
        'mutation TimesheetsPageDownloadLastMonthMutation {\n  downloadLastMonthTimesheets\n}\n',
    },
  };
})();
(node as any).hash = '4dbe35f83c8424fd098a4084abda97ce';
export default node;
