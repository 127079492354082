import React, {useState} from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Link,
  Typography,
} from '@material-ui/core';
import 'date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import {ErrorMessage, Form, Formik} from 'formik';
import FormItem from '../../atoms/FormItem';
import {FTextField} from '../../modules/FMaterial/FTextfield/FTextField';
import {FTextFieldPassword} from '../../modules/FMaterial/FTextfield/FTextFieldPassword';
import {useNavigate} from 'react-router';
import * as yup from 'yup';
import graphql from 'babel-plugin-relay/macro';
import {useMutation} from 'relay-hooks';
import './SignUpPageStyling.css';
import {SignUpPageMutation} from '../../__generated__/SignUpPageMutation.graphql';
import {Toast} from '../../modules/Toast';
import {useAuthContext} from '../../context/AuthContext';
import {makeStyles} from '@material-ui/core/styles';
import {FDropdown} from '../../modules/FMaterial/FDropdown/FDropdown';

const useStyles = makeStyles(() => ({
  errorMessage: {
    color: '#f44336',
    fontFamily: 'Montserrat,Roboto',
    fontSize: '0.75rem',
  },
}));

const signUpSchema = yup.object().shape({
  email: yup.string().email('Email not valid').required('Email is required.'),
  confirmEmail: yup
    .string()
    .email('Email not valid')
    .oneOf([yup.ref('email'), undefined], "Emails don't match")
    .required('Confirm email is required'),
  password: yup
    .string()
    .required('Password is required.')
    .min(5, 'Please make sure your password has a minimum of 5 characters'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), undefined], "Passwords don't match")
    .required('Confirm Password is required'),
  lastName: yup.string().required('Please enter your last name'),
  firstName: yup.string().required('Please enter your first name'),
  incomeTaxNumber: yup.string().required('Please enter your income tax number'),
  personalReferenceValue: yup.string().required('This field is required'),
  personalReferenceType: yup.string().required('This field is required'),
  dateOfBirth: yup.string().required('This field is required'),
  mobileNumber: yup.string(),
});

export function SignUpPage() {
  const classes = useStyles();
  const navigate = useNavigate();
  const {auth, handleLogin} = useAuthContext();
  const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null);

  const [mutation, {loading}] = useMutation<SignUpPageMutation>(graphql`
    mutation SignUpPageMutation($input: RegisterUserInput!) {
      registerUser(input: $input) {
        processInstanceId
      }
    }
  `);
  React.useEffect(() => {
    if (auth.authenticated) {
      navigate('/');
    }
  }, [navigate, auth.authenticated]);

  if (auth.authenticated) {
    return <></>;
  }

  return (
    <Box display="flex" justifyContent="center" pl={5}>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          mobileNumber: '',
          incomeTaxNumber: '',
          personalReferenceValue: '',
          personalReferenceType: '',
          dateOfBirth: '',
        }}
        validationSchema={signUpSchema}
        onSubmit={async ({
          firstName,
          lastName,
          email,
          password,
          mobileNumber,
          incomeTaxNumber,
          personalReferenceValue,
          personalReferenceType,
          dateOfBirth,
        }) => {
          try {
            const response = await mutation({
              variables: {
                input: {
                  firstName,
                  lastName,
                  email,
                  password,
                  mobileNumber,
                  incomeTaxNumber,
                  personalReferenceValue,
                  personalReferenceType,
                  dateOfBirth,
                },
              },
            });
            if (response) {
              const loginResponse = await handleLogin(email, password);
              if (!loginResponse.ok) {
                Toast('error', 'Login failed');
              }
              Toast('success', 'Successfully created account');
              navigate('/');
            } else {
              Toast('error', 'Something went wrong');
            }
          } catch (error) {
            Toast('error', error.message);
          }
        }}
      >
        {({submitForm, setFieldValue}) => {
          return (
            <Box width="100%">
              <Form
                style={{display: 'flex', justifyContent: 'center'}}
                onKeyDown={(event) => {
                  if (event.keyCode === 13) {
                    event.preventDefault();
                    submitForm();
                  }
                }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  width="500px"
                  pb={10}
                >
                  <Box pb={3} textAlign="center" maxWidth="500px">
                    <Typography variant="h5">Sign Up</Typography>
                  </Box>

                  <FormItem>
                    <FTextField
                      fullWidth
                      field={{name: 'firstName'}}
                      label="First Name"
                    />
                  </FormItem>

                  <FormItem>
                    <FTextField
                      fullWidth
                      field={{name: 'lastName'}}
                      label="Last Name"
                    />
                  </FormItem>

                  <FormItem>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        helperText={
                          <Typography className={classes.errorMessage}>
                            <ErrorMessage name="dateOfBirth" />
                          </Typography>
                        }
                        fullWidth
                        variant="inline"
                        format="MM-dd-yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="Date of Birth (MM-DD-YYYY)"
                        value={dateOfBirth}
                        autoOk={true}
                        onChange={(value: Date | null) => {
                          setFieldValue(
                            'dateOfBirth',
                            moment(value).format('YYYY-MM-DD')
                          );
                          setDateOfBirth(value);
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </FormItem>

                  <FormItem>
                    <FDropdown
                      field={{name: 'personalReferenceType'}}
                      fullWidth
                      label={'Personal Reference Type'}
                      data={[
                        {
                          id: 'SA',
                          description: 'South African ID',
                        },
                        {
                          id: 'P',
                          description: 'Passport',
                        },
                        {
                          id: 'OTH',
                          description: 'Other',
                        },
                      ]}
                    />
                  </FormItem>

                  <FormItem>
                    <FTextField
                      fullWidth
                      field={{name: 'personalReferenceValue'}}
                      label="Personal Reference Value"
                    />
                  </FormItem>

                  <FormItem>
                    <FTextField
                      fullWidth
                      field={{name: 'incomeTaxNumber'}}
                      label="Income Tax Number"
                    />
                  </FormItem>

                  <FormItem>
                    <FTextField
                      fullWidth
                      field={{name: 'mobileNumber'}}
                      label="Cellphone / Telephone Number"
                    />
                  </FormItem>

                  <FormItem>
                    <FTextField
                      fullWidth
                      field={{name: 'email'}}
                      label="E-mail"
                    />
                  </FormItem>

                  <FormItem>
                    <FTextField
                      fullWidth
                      field={{name: 'confirmEmail'}}
                      label="Confirm E-mail"
                    />
                  </FormItem>

                  <FormItem>
                    <FTextFieldPassword
                      fullWidth
                      field={{
                        name: 'password',
                      }}
                      type="password"
                      label="Password"
                    />
                  </FormItem>

                  <FormItem>
                    <FTextFieldPassword
                      fullWidth
                      field={{
                        name: 'confirmPassword',
                      }}
                      type="password"
                      label="Confirm password"
                    />
                  </FormItem>
                  <FormItem>
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      fullWidth
                      onClick={() => {
                        submitForm();
                      }}
                    >
                      {loading ? (
                        <CircularProgress size={26} style={{color: 'white'}} />
                      ) : (
                        'Happy, Create Account'
                      )}
                    </Button>
                  </FormItem>
                  <Box display="flex" justifyContent="center" pb={3}>
                    <Link
                      component={'button'}
                      color="secondary"
                      onClick={() => {
                        navigate('/login');
                      }}
                    >
                      Sign In
                    </Link>
                  </Box>
                </Box>
              </Form>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
}
