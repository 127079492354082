import React, {useContext} from 'react';
import {
  Box,
  createStyles,
  Link,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import graphql from 'babel-plugin-relay/macro';
import {useQuery, useRefetchable} from 'relay-hooks/lib';
import {makeStyles} from '@material-ui/core/styles';
import {Form, Formik} from 'formik';
import {useNavigate} from 'react-router';
import {TaskPageQuery} from '../../__generated__/TaskPageQuery.graphql';
import {ViewLeaveRequest} from '../../modules/ActionTask/ActionLeave';
import {useAuthContext} from '../../context/AuthContext';
import {TaskPage_tasks$key} from '../../__generated__/TaskPage_tasks.graphql';

const useStyles = makeStyles(() =>
  createStyles({
    columnHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '0px',
      width: '100%',
      flexDirection: 'row',
    },
    cell: {
      '&:hover': {
        backgroundColor: '#faf9f7',
        cursor: 'pointer',
      },
    },
    link: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  })
);

const query = graphql`
  query TaskPageQuery($search: String, $first: Int, $after: String) {
    viewer {
      user {
        ...TaskPage_tasks
      }
    }
  }
`;

const fragment = graphql`
  fragment TaskPage_tasks on User
    @refetchable(queryName: "TaskPageRefetchQuery") {
    id
    firstName
    lastName
    email
    mobileNumber
    tasks(q: $search, first: $first, after: $after) {
      total
      edges {
        node {
          id
          description
          dateCreated
          taskId
          taskType
          time
        }
      }
    }
  }
`;
interface Props {
  node: TaskPage_tasks$key | null;
}

interface SearchProps {
  search: string;
}

export function TaskPage({node}: Props) {
  const classes = useStyles();
  // const navigate = useNavigate();

  const viewLeaveRequest = useContext(ViewLeaveRequest);

  const [fragmentNode, refetch] = useRefetchable(fragment, node);
  const userId = fragmentNode?.id;

  // const userId = props?.viewer.user?.id;

  /*const handleChangeRowsPerPage = (
            event: React.ChangeEvent<HTMLInputElement>
        ) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };*/

  /*  React.useEffect(() => {
              refetch({
                  id: userId || '',
                  search: searchValue,
                  first: rowsPerPage,
                  after: searchValue
                      ? btoa(`arrayconnection:-1`)
                      : btoa(`arrayconnection:${rowsPerPage * page - 1}`),
              });
          }, [refetch, searchValue, rowsPerPage, page, userId]);*/

  return (
    <Box>
      <Box
        pl={6}
        pb={3}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Typography variant="h4">Tasks</Typography>

        {/*<Button
                        color={'primary'}
                        onClick={() => {
                            navigate('/leaveapplication');
                        }}
                    >
                        Apply for leave
                    </Button>*/}
      </Box>

      <Box>
        <Formik<SearchProps>
          initialValues={{
            search: '',
          }}
          onSubmit={() => {}}
        >
          {({submitForm}) => {
            return (
              <Box display="flex" justifyContent="center" pl={5} pb={5}>
                <Form
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                  onKeyDown={(event) => {
                    if (event.keyCode === 13) {
                      event.preventDefault();
                      submitForm();
                    }
                  }}
                >
                  <TableContainer
                    style={{
                      borderRadius: '4px',
                      boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2)',
                    }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Typography variant={'h6'}>Tasks</Typography>
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        {/*<TableRow>
                                                        <TableCell colSpan={2}>
                                                            <FTextField
                                                                field={{name: 'search'}}
                                                                label="Search"
                                                            />
                                                        </TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>*/}
                        <TableRow>
                          <TableCell className={classes.cell}>
                            <Box className={classes.columnHeader}>
                              <Typography>Date Submitted</Typography>
                            </Box>
                          </TableCell>
                          <TableCell className={classes.cell}>
                            <Box className={classes.columnHeader}>
                              <Typography>Type</Typography>
                            </Box>
                          </TableCell>
                          <TableCell className={classes.cell}>
                            <Box className={classes.columnHeader}>
                              <Typography>Description</Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {fragmentNode?.tasks.edges.map((task) => {
                        return (
                          <TableRow>
                            <TableCell>{task.node.dateCreated}</TableCell>
                            <TableCell>{task.node.taskType}</TableCell>
                            <TableCell>
                              <Link
                                className={classes.link}
                                onClick={() => {
                                  viewLeaveRequest.notify({
                                    taskId: task.node.taskId,
                                    handlePromptResponse: () => {},
                                    onClose: () => {
                                      refetch({
                                        id: userId || '',
                                      });
                                    },
                                  });
                                }}
                              >
                                {task.node.description}
                              </Link>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </Table>
                    {/*<TablePagination
                                                rowsPerPageOptions={[5, 10, 25]}
                                                rowsPerPage={rowsPerPage}
                                                component="span"
                                                count={
                                                    props?.viewer?.user?.tasks
                                                        ? props?.viewer?.user?.tasks.length
                                                        : 0
                                                }
                                                page={page}
                                                onChangePage={(_event, pageNumber) => {
                                                    setPage(pageNumber);
                                                }}
                                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                            />*/}
                  </TableContainer>
                </Form>
              </Box>
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
}

export function TaskPageWrapper() {
  const {auth} = useAuthContext();

  const navigate = useNavigate();

  React.useEffect(() => {
    if (!auth.authenticated) {
      navigate('/login');
    }
  }, [navigate, auth.authenticated]);

  const {props} = useQuery<TaskPageQuery>(query);
  if (!props) {
    return <> </>;
  }
  return <TaskPage node={props?.viewer.user} />;
}
