import React from 'react';
import AuthContextProvider from './@ssquared/sep/web/context/AuthContext';
import RelayContextProvider from './@ssquared/sep/web/context/RelayContextProvider';
import {ThemeController} from './@ssquared/sep/web/context/ThemeController';
import {LocationProvider} from './@ssquared/sep/web/context/LocationContext';
import {ConfirmDialogProvider} from './@ssquared/sep/web/modules/ConfirmDialogProvider/ConfirmDialogProvider';
import {ToastContainer} from 'react-toastify';
import {BrowserRouter} from 'react-router-dom';
import {Navigation} from './@ssquared/sep/web/modules/Navigation/Navigation';
import {NewPassword} from './@ssquared/sep/web/modules/NewPassword/NewPassword';
import {ActionLeave} from './@ssquared/sep/web/modules/ActionTask/ActionLeave';

function App() {
  return (
    <AuthContextProvider>
      <RelayContextProvider>
        <ThemeController>
          <LocationProvider>
            <ConfirmDialogProvider>
              <ActionLeave>
                <NewPassword>
                  <ToastContainer position="top-right" autoClose={7000} />
                  <BrowserRouter>
                    <Navigation />
                  </BrowserRouter>
                </NewPassword>
              </ActionLeave>
            </ConfirmDialogProvider>
          </LocationProvider>
        </ThemeController>
      </RelayContextProvider>
    </AuthContextProvider>
  );
}

export default App;
